import { Player } from '@remotion/player'
import { useEffect, useMemo, useState } from 'react'
import { useNecessary } from '../../../hooks/necessary'

let frameCount = 241;

const YourComponent: React.FC = () => {
  const [frame, setFrame] = useState<number>(0);
  const {images, isActive} = useNecessary()

  useEffect(() => {
    if (isActive) {
      if (images.length > 0) {
        let animationFrameId: number;
  
        const animate = () => {
          setFrame((prevFrame) => (prevFrame + 1) % images.length);
          animationFrameId = requestAnimationFrame(animate);
        };
  
        animationFrameId = requestAnimationFrame(animate);
  
        return () => cancelAnimationFrame(animationFrameId);
      }
    }
  }, [images, isActive]);

  const imageUrl = useMemo(() => images[frame]?.src, [frame, images]);

  return (
    <img
      src={imageUrl}
      alt="Frame"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        transform: 'translate(-50%, -50%)',
        objectFit: 'cover',
      }}
    />
  );
};

const BackgroundGame: React.FC = ({}) => {
  interface sizes {
    width: number,
    height: number
  }
  const [compositionSize, setCompositionSize] = useState<sizes>({ width: 375, height: 667 });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 375) {
        setCompositionSize({
          width: 375,
          height: 667,
        });
      } else if (screenWidth <= 390) {
        setCompositionSize({
          width: 390,
          height: 844,
        });
      } else if (screenWidth <= 414) {
        setCompositionSize({
          width: 414,
          height: 836,
        });
      } else {
        setCompositionSize({
          width: 430,
          height: 932,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        left: 0,
      }}
    >
      <Player
        component={YourComponent}
        durationInFrames={frameCount}
        compositionWidth={compositionSize?.width}
        compositionHeight={compositionSize?.height}
        fps={30}
        loop
        autoPlay
      />
    </div>
  );
};

export default BackgroundGame;
