import { useEffect, useState } from 'react'


interface Props {
    tap: number
    stamina: number
    regeneration: number
    water: number
    setWater: React.Dispatch<React.SetStateAction<number>>
}

const WaterProgressBar = ({ tap, stamina, regeneration, water, setWater }: Props) => {
    const [width, setWidth] = useState((water / stamina) * 100)

    useEffect(() => {
        const scaledCurrentWater = Math.min((water / stamina) * 100, 100)
        setWidth(scaledCurrentWater)
    }, [water, stamina])


    useEffect(() => {
        if (tap > 0 && water > 0) {
            setWater(prevWater => Math.max(prevWater - 3, 0))
        }
    }, [tap])


    useEffect(() => {
        const intervalTime = (3000 - regeneration * 100)
        const interval = setInterval(() => {
            setWater(prevWater => Math.min(prevWater + 1, stamina))
        }, intervalTime)

        return () => clearInterval(interval)
    }, [regeneration, stamina])



    return (
        <>
            <div
                className="absolute top-0 left-1 h-full rounded-full transition-all duration-300 z-[0] max-w-[240px]"
                style={{
                    width: `${width}%`,
                    background: 'linear-gradient(82.14deg, #D6FAF2 0%, #75F0D3 98.94%)',
                }}
            />
        </>
    )
}

export default WaterProgressBar
