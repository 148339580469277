import { initUtils } from '@tma.js/sdk'
import { useEffect, useState } from 'react'
import { ReactComponent as Copy } from '../components/images/friendspage/copy.svg'
import { friends, friendsData } from '../components/models'
import useStore from '../components/store/zustand'
import InviteLink from '../components/ui/friendspage/inviteLink'
import InvitedTable from '../components/ui/friendspage/invitedTable'
import SuccessSnackbar from '../hooks/successSnackbar'
import { useTelegram } from '../hooks/useTelegram'
import useApi from '../services/api/apiHandlerService'
const Friendspage = () => {
const {userId} = useTelegram()
const [copySuccess, setCopySuccess] = useState<string>('')
const [link, setLink] = useState<string>(`https://t.me/CamelRacingClub_bot?start=${userId}`)
const [reward, setReward] = useState<number>(0)
const [friends, setFriends] = useState<friendsData[]>([])
const [referal, setReferal] = useState<number>(0)
const [loading, setLoading] = useState<boolean>(false)
const {identityId} = useStore()
const api = useApi()
const utils = initUtils()

const handleShareClick = () => {
	if (userId) {
		const shareUrll = `https://t.me/CamelRacingClub_bot?start=${userId}`
		utils.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(shareUrll)}`);
	} else {
		console.error('User ID is not available.');
	}
};

const copyToClipboard = () => {
	if (navigator.clipboard) {
		navigator.clipboard.writeText(link).then(() => {
			setCopySuccess('The link was copied')
		}).catch((error) => {
			setCopySuccess('The link was not copied')
		})
	} else {
		const textarea = document.createElement('textarea')
		textarea.value = link
		document.body.appendChild(textarea)
		textarea.select()
		try {
			document.execCommand('copy')
			setCopySuccess('The link was copied')
		} catch (error) {
			setCopySuccess('The link was not copied')
		}
		document.body.removeChild(textarea)
	}
	setTimeout(() => setCopySuccess(''), 2000)
}

const getFriends = async():Promise<void> => {
	if (!identityId) return
	setLoading(true)
	const res = await api<friends>({
		url: '/friend/',
		method: 'GET',
		headers: {
			'x-user-id': identityId
		}
	})
	setLoading(false)
	if (res) {
		setFriends(res.data)
		setReferal(res.data.length)
		setReward(res?.reward)
	}
}

useEffect(() => {
	document.documentElement.style.overflow = 'hidden';
	document.body.style.overflow = 'hidden';

	return () => {
		document.documentElement.style.overflow = '';
		document.body.style.overflow = '';
	};
}, []);

	return (
		<>
		<div className='text-center text-white text-3xl font-bold'>
		Invite Friend
		</div>
		<p className='text-center text-sm text-ColorsChapter2-500'>Invite friends to earn more Points</p>

		<div className='flex justify-center font-Montserrat'>
			  <div className='max-w-[350px] w-full'>
					<div className='mt-4'>
						<InviteLink reward={reward} getFriends={getFriends}/>
					</div>

					<div className='flex justify-between flex-wrap items-center text-white mt-4'>

						<button className='bg-ColorsChapter2-150 rounded-xl py-3 p-2 w-[280px] custom-sm:w-[250px] border border-ColorsChapter2-350 text-ColorsChapter2-50 font-medium' onClick={() => handleShareClick()}>
							Invite a Friends
						</button>

						<button className='bg-ColorsChapter2-150 border border-ColorsChapter2-350 p-2 px-3 py-3 rounded-xl' onClick={() => copyToClipboard()}>
							<Copy/>
						</button>

					</div>

					<div className='mt-5'>
						<InvitedTable setReward={setReward}  loading={loading} friends={friends} referal={referal} getFriends={getFriends}/>
					</div>

					</div>

			  </div>
				

				<SuccessSnackbar message={copySuccess} setCopySuccess={setCopySuccess}/>
		</>
	)
}

export default Friendspage