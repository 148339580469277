import { useEffect, useState } from "react"
import { useIdentityHook } from "../../../hooks/identities/identity"
import { useTelegram } from '../../../hooks/useTelegram'

type Props = {
	nickname: string;
	setNickname: React.Dispatch<React.SetStateAction<string>>;
	setNicknameState: React.Dispatch<React.SetStateAction<boolean | null>>
};

const NicknameInput: React.FC<Props> = ({ nickname, setNickname, setNicknameState }) => {
	const { isNicknameAvailableAsync } = useIdentityHook();
	const [status, setStatus] = useState<boolean | null>(null);
	const {user} = useTelegram()
	const sendNicknameToBackend = async (username: string) => {
		const nicknameAvailable = await isNicknameAvailableAsync(username);
		setStatus(nicknameAvailable);
		setNicknameState(nicknameAvailable)
	};

	useEffect(() => {
		if (nickname) {
			const timeoutId = setTimeout(() => {
				sendNicknameToBackend(nickname);
			}, 300);

			return () => clearTimeout(timeoutId);
		} else {
			setStatus(null);
		}
	}, [nickname]);

	useEffect(() => {
		setNickname(user || '')
	}, [])

	return (
		<div className="w-full flex flex-col items-center justify-center text-white">
			<input
				type="text"
				value={nickname}
				placeholder="Enter your nickname"
				onChange={(e) => setNickname(e.target.value)}
				className={`w-full border-b-[2px] outline-none bg-transparent pb-3 text-center text-xl ${
					status === null ? 'border-ColorsChapter2-250' : status ? 'border-red-600' : 'border-green-600'
				}`}
			/>

			{	status === null ? null : status ? (
					<p className='text-red-600 text-[12px] text-center w-full mt-1'>
							Crap! That username is not available
					</p>
			) : (
				  <p className='text-green-600 text-[12px] text-center w-full mt-1'>
							Great choice! That username is available
					</p>
			)}
	
		</div>
	);
};

export default NicknameInput;
