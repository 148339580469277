// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeAndMoveUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.tapText {
  position: absolute;
  color: white;
  font-size: 9vh;
  white-space: nowrap;
  animation: fadeAndMoveUp 1s forwards;
  z-index: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;

  text-shadow:
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 15px #fff,
    0 0 20px #fff,
    0 0 30px #fff,
    0 0 40px #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/gamepage/gamemodule.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;;EAEA;IACE,UAAU;IACV,4BAA4B;EAC9B;AACF;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,oCAAoC;EACpC,UAAU;EACV,2BAA2B;EAC3B,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;;EAElB;;;;;;iBAMe;AACjB","sourcesContent":["@keyframes fadeAndMoveUp {\n  0% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n\n  100% {\n    opacity: 0;\n    transform: translateY(-20px);\n  }\n}\n\n.tapText {\n  position: absolute;\n  color: white;\n  font-size: 9vh;\n  white-space: nowrap;\n  animation: fadeAndMoveUp 1s forwards;\n  z-index: 0;\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  user-select: none;\n  touch-action: none;\n\n  text-shadow:\n    0 0 5px #fff,\n    0 0 10px #fff,\n    0 0 15px #fff,\n    0 0 20px #fff,\n    0 0 30px #fff,\n    0 0 40px #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
