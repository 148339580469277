import { useNavigate } from 'react-router-dom'

interface TelegramUser {
    id: number
    username: string
    first_name: string
}

interface TelegramData {
    user: TelegramUser | null
}

type TelegramHapticFeedback = {
    impactOccurred: (
        style: "light" | "medium" | "rigid" | "heavy" | "soft",
    ) => void
    notificationOccurred: (type: "error" | "success" | "warning") => void
}

interface TelegramWebApp {
    initDataUnsafe?: TelegramData
    close: () => void
    platform?: string
    BackButton?: {
        show: () => void
        hide: () => void
        onClick: (callback: () => void) => void
        offClick: (callback: () => void) => void
    }
    HapticFeedback: TelegramHapticFeedback
}

const tg: TelegramWebApp = (window as any).Telegram.WebApp

export function useTelegram() {
    const navigate = useNavigate()

    const onClose = () => {
        tg.close()
    }

    const userId = tg.initDataUnsafe?.user?.id || null
    const user = tg.initDataUnsafe?.user?.username || null
    const name = tg.initDataUnsafe?.user?.first_name || null

    return {
        onClose,
        tg,
        userId,
        user,
        name,
    }
}
