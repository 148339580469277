import { initUtils } from '@tma.js/sdk'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNecessary } from '../../../hooks/necessary'
import { useTelegram } from '../../../hooks/useTelegram'
import useApi from "../../../services/api/apiHandlerService"
import Coin from '../../images/coin.png'
import Loading from '../../images/loading.gif'
import boost from '../../images/taskpage/boost.png'
import { ReactComponent as Discord } from '../../images/taskpage/discord.svg'
import { ReactComponent as Friends } from '../../images/taskpage/friends.svg'
import { ReactComponent as Instagram } from '../../images/taskpage/instagram.svg'
import { ReactComponent as Telegram } from '../../images/taskpage/telegram.svg'
import { ReactComponent as TikTok } from '../../images/taskpage/tiktok.svg'
import { ReactComponent as Twitter } from '../../images/taskpage/twitter.svg'
import { ReactComponent as Welcome } from '../../images/taskpage/welcome.svg'
import { ReactComponent as Youtube } from '../../images/taskpage/youtube.svg'
import { infoTaskModal, missionsInterface } from '../../models'
type Props = {
  setCompleted: React.Dispatch<React.SetStateAction<number>>;
  setLenghtOfmission: React.Dispatch<React.SetStateAction<number>>;
  setClaimed: React.Dispatch<React.SetStateAction<boolean | null>>;
  setReward: React.Dispatch<React.SetStateAction<number>>;
  getMissions: () => void
  missions: missionsInterface | undefined
  loading: boolean
};

const TaskTable: React.FC<Props> = ({ missions,getMissions, loading }) => {
  const navigate = useNavigate();
  const { identityId } = useNecessary();

  const [verifyingMap, setVerifyingMap] = useState<{ [key: string]: boolean }>({});
  const [infoForModal, setInfoForModal] = useState<infoTaskModal>({
    id: '',
    name: '',
    reward: 0,
    link: '',
    status: '',
    icon_type: ''
  });
  const { tg } = useTelegram();
  const api = useApi();
  const {getData} = useNecessary()

 

  useEffect(() => {
    getMissions();
  }, [identityId]);

  const startMission = async (id: string) => {
    await api({
      method: 'POST',
      url: `/mission/navigate?mission_id=${id}`,
      headers: {
        'x-user-id': identityId
      }
    });
    getMissions();
  };

  const checkMission = async (id: string) => {
    setVerifyingMap(prev => ({ ...prev, [id]: true }));
    try {
      await api({
        method: 'POST',
        url: `/mission/check?mission_id=${id}`,
        headers: {
          'x-user-id': identityId
        }
      });
      await getMissions();
    } catch (e) {
      console.log(e);
    } finally {
      setVerifyingMap(prev => ({ ...prev, [id]: false }));
    }
  };

  const claimMission = async (id: string) => {
    await api({
      method: 'POST',
      url: `/mission/claim?mission_id=${id}`,
      headers: {
        'x-user-id': identityId
      }
    });
    getData()
    await getMissions();
  };

  interface statusProps {
    status: string;
    id: string;
    link: string;
    type: string;
  }

  const GetStatus = ({ status, id, link, type }: statusProps) => {
    const utils = initUtils();
    const { tg } = useTelegram();
    const eventHandler = tg.platform === 'tdesktop' ? 'onClick' : 'onTouchStart';

    const handleButtonClick = async (id: string, link: string) => {
      try {
        if (type === 'Telegram') {
          utils.openTelegramLink(link);
          await startMission(id);
        } else if (type === 'Boost') {
          navigate('/');
        } else if (type === 'Friend') {
          navigate('/friends');
        } else {
          utils.openLink(link);
          await startMission(id);
        }
        getMissions();
      } catch (error) {
        console.error('Error verifying reward', error);
      }
    };

    switch (status) {
      case 'open':
        return (
          <div>
            <div
              className='flex items-center justify-center bg-ColorsChapter2-50 rounded-lg border border-myColor-850 text-black py-[6px] px-3 font-medium cursor-pointer mr-1'
              {...{ [eventHandler]: () => handleButtonClick(id, link) }}
            >
              <p>Start</p>
            </div>
          </div>
        );
      case 'execution':
        return (
          <>
            {verifyingMap[id] ? (
              <p className='text-sm text-center font-medium px-5'>Verifying...</p>
            ) : (
              <div
                className='flex items-center justify-center bg-myColor-900 rounded-lg border border-myColor-950 font-medium cursor-pointer mr-1 py-1 px-3 text-white opacity-55'
                {...{ [eventHandler]: () => checkMission(id) }}
              >
                <p>Check</p>
              </div>
            )}
          </>
        );
      case 'verified':
        return (
          <div
            className='flex items-center justify-center bg-ColorsChapter2-50 rounded-lg border border-myColor-850 text-black font-medium cursor-pointer py-1 px-3'
            {...{ [eventHandler]: () => claimMission(id) }}
          >
            <p className='font-medium'>Claim</p>
          </div>
        );
      case 'completed':
        return (
          <div className='py-2 px-1 flex items-center justify-center bg-myColor-900 rounded-2xl border border-myColor-950 font-medium opacity-20 text-sm'>
            Completed
          </div>
        );
      default:
        return null;
    }
  };

  interface iconProps {
    icon: string;
  }

  const GetIcon = ({ icon }: iconProps) => {
    switch (icon) {
      case 'Welcome':
        return <Welcome className={`w-[45px]`} />;
      case 'YouTube':
        return <Youtube className={`w-[45px]`} />;
      case 'Instagram':
        return <Instagram className={`w-[45px]`} />;
      case 'Discord':
        return <Discord className={`w-[45px]`} />;
      case 'Telegram':
        return <Telegram className={`w-[45px]`} />;
      case 'Tiktok':
        return <TikTok className={`w-[45px]`} />;
      case 'X':
        return <Twitter className={`w-[45px]`} />;
      case 'Boost':
        return <img src={boost} className={`w-[45px]`} />;
      case 'Friend':
        return <Friends className={`w-[45px]`} />;
      default:
        return null;
    }
  };

  return (
    <>
      {loading ? (
        <div className='w-full flex justify-center mt-12'>
          <img src={Loading} alt="" className='w-[40px] h-[40px]' />
        </div>)
        : (
          <>
            <p className='font-bold'>Tasks <span className='font-normal text-ColorsChapter2-500'>{missions?.data?.length}</span></p>
            <div className='h-[330px] h-xs:h-[280px] overflow-y-scroll'>
              {missions?.data.map((mission) => (
                <div className={`flex justify-between mt-2 items-center text-sm py-2 px-2 text-white bg-ColorsChapter2-150 rounded-3xl h-[70px] h-xs:h-[50px] border border-ColorsChapter2-200`} key={mission.id}>
                  <div className='flex gap-2 items-center ml-2'>
                    <GetIcon icon={mission.icon_type} />
                    <div className='flex-col'>
                      <p className='font-medium text-sm text-ColorsChapter2-500'>{mission.name}</p>
                      <div className='flex items-center gap-1'>
                        <img src={Coin} className='w-[20px] h-[20px]' alt="" />
                        <p className='text-[13px] font-medium'>+{mission.reward} points</p>
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <GetStatus status={mission.status} type={mission.icon_type} id={mission.id} link={mission.link} />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
    </>
  );
};

export default TaskTable;
