import { useEffect } from 'react'
import HowToPlay from '../components/ui/howToPlay'
import { useNecessary } from '../hooks/necessary'


const HowToPlaypage = () => {
	const {setFirstVisit} = useNecessary()

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'
    document.body.style.overflow = 'hidden'

    return () => {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
    }
  }, [])
  
	return (
		<>
		<div className='background opacity-10'/>
          <div className="fixed inset-0 flex items-center justify-center z-50">
             <div className='w-full flex justify-center items-center'>
                <div className='max-w-[350px] w-full justify-center items-center'>
                  <div className='mt-10'>
                    <HowToPlay setFirstVisit={setFirstVisit}/>
                  </div>
                </div>
           </div>
          </div>
		</>
	)
}

export default HowToPlaypage