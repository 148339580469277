import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../components/ui/background.css'
import CropAvatar from '../components/ui/registrationpage/cropAvatar'
import NicknameInput from '../components/ui/registrationpage/nicknameInput'
import Welcome from '../components/ui/registrationpage/welcome'
import { useIdentityHook } from '../hooks/identities/identity'
import { useTelegram } from '../hooks/useTelegram'
import useApi from '../services/api/apiHandlerService'

const RegistrationPage = () => {
    const { userId } = useTelegram()
    const [step, setStep] = useState(0)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const api = useApi()
    const [src, setSrc] = useState<any>('')
    const [preview, setPreview] = useState<string>('')
    const [nickname, setNickname] = useState<string>('')
    const navigate = useNavigate()
    const [file, setFile] = useState<File | null>(null)
    const [nicknameState, setNicknameState] = useState<boolean | null>(null)
    const { signUpAsync, signInAsync } = useIdentityHook()
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

    useEffect(() => {
        // Отслеживаем изменение размера окна для определения, когда появляется клавиатура
        const handleResize = () => {
            setIsKeyboardVisible(window.innerHeight < document.documentElement.clientHeight)
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        setIsFirstRender(false)
    }, [])

    const handleNextStep = () => {
        if (step < 1) {
            setStep((prevStep) => prevStep + 1)
        }
    }

    const pageVariants = {
        initial: isFirstRender ? {} : { x: '100%', opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: '-100%', opacity: 0 },
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]

            if (!file.type.startsWith('image/')) {
                setError('Please select a valid image file.')
                return
            }

            setError(null)

            const reader = new FileReader()

            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    setSrc(reader.result)
                }
            }

            reader.readAsDataURL(file)
        }
    }

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const signUp = async () => {
        await signUpAsync(userId, nickname, file)
        signInAsync(userId)
        navigate('/')
    }

    return (
        <>
            <div className='background opacity-10' />
            <div className='w-full flex flex-col justify-center items-center'>
                <div className='max-w-[350px] w-full justify-center items-center'>
                    <motion.div
                        key={step}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                        variants={pageVariants}
                        transition={{ duration: 0.5 }}
                    >
                        {step === 0 && (
                            <>
                                <div className='mt-20'>
                                    <Welcome src={src} setSrc={setSrc} setError={setError} />
                                </div>
                                <div className='mt-14'>
                                    <NicknameInput setNickname={setNickname} nickname={nickname} setNicknameState={setNicknameState} />
                                </div>
                            </>
                        )}
                        {step === 1 && (
                            <>
                                <div className='mt-20'>
                                    <CropAvatar setPreview={setPreview} preview={preview} src={src} setFile={setFile} />
                                </div>
                            </>
                        )}
                    </motion.div>

                    {step === 0 && (
                        <>
                            {nicknameState === false && nickname.length > 0 ? (
                                <div className={`fixed w-full bottom-0 left-0 flex justify-center mb-12 ${isKeyboardVisible ? 'hidden' : ''}`}>
                                    <button
                                        onClick={handleNextStep}
                                        className='bg-ColorsChapter2-50 text-black px-4 py-2 rounded-lg font-medium mx-5 w-full'
                                    >
                                        Continue
                                    </button>
                                </div>
                            ) : (
                                <div className={`fixed w-full bottom-0 left-0 flex justify-center mb-12 ${isKeyboardVisible ? 'hidden' : ''}`}>
                                    <button className='bg-ColorsChapter2-50 text-black px-4 py-2 rounded-lg font-medium mx-5 w-full opacity-55'>
                                        Continue
                                    </button>
                                </div>
                            )}
                        </>
                    )}

                    {step === 1 && (
                        <>
                            <div className='absolute bottom-5 w-full left-0 flex justify-center'>
                                <div className='w-full max-w-[350px] flex flex-col'>
                                    <button onClick={signUp} className='bg-ColorsChapter2-50 text-black px-4 py-2 rounded-lg w-full mt-24 font-medium'>
                                        {preview ? 'Continue' : 'Skip'}
                                    </button>

                                    {preview ? (
                                        <button
                                            className='text-ColorsChapter2-50 px-4 py-2 rounded-lg mt-2 w-full font-medium bg-ColorsChapter2-150 border border-ColorsChapter2-250'
                                            onClick={handleButtonClick}
                                        >
                                            Select Different Image
                                        </button>
                                    ) : (
                                        <button className='text-ColorsChapter2-50 px-4 py-2 rounded-lg mt-2 w-full font-medium bg-ColorsChapter2-150 border border-ColorsChapter2-250 opacity-55'>
                                            Select Different Image
                                        </button>
                                    )}
                                </div>
                                <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default RegistrationPage
