import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { booster } from '../../../types/gameAttributes/booster'
import { camel } from '../../../types/gameAttributes/camel'
import { ReactComponent as Arrow } from '../../images/arrows.svg'
import FirstBooster from '../../images/boosters/firstBooster.gif'
import SecondBooster from '../../images/boosters/secondBooster.gif'
import ThirdBooster from '../../images/boosters/thirdBooster.gif'
import firstCamelIcon from '../../images/camels/camelFirst.gif'
import SecondCamelIcon from '../../images/camels/camelSecond.gif'
import ThirdCamelIcon from '../../images/camels/camelThird.gif'
import { ReactComponent as Lock } from '../../images/lock.svg'

type Props = {
  mode: string;
  camels: camel[];
  boosters: booster[];
  setCamelIndex: React.Dispatch<React.SetStateAction<number>>;
  camelIndex: number;
};

const SelectCamelOrBooster: React.FC<Props> = ({ mode, camels, boosters, setCamelIndex, camelIndex }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [preloadedImages, setPreloadedImages] = useState<Record<string, HTMLImageElement>>({});

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    const preloadImages = (images: Record<string, string>) => {
      const loadedImages: Record<string, HTMLImageElement> = {};
      Object.entries(images).forEach(([key, src]) => {
        const img = new Image();
        img.src = src;
        loadedImages[key] = img;
      });
      setPreloadedImages(loadedImages);
    };

    preloadImages({
      'first_lvl_camel': firstCamelIcon,
      'second_lvl_camel': SecondCamelIcon,
      'third_lvl_camel': ThirdCamelIcon,
      'first_lvl_booster': FirstBooster,
      'second_lvl_booster': SecondBooster,
      'third_lvl_booster': ThirdBooster,
    });
  }, []);

  const handleNextPage = () => {
    if (mode === 'camel') {
      if (camelIndex < camels.length - 1) {
        setCamelIndex((prevIndex) => prevIndex + 1);
      }
    } else {
      if (camelIndex < boosters.length - 1) {
        setCamelIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const handlePrevPage = () => {
    if (camelIndex !== 0) {
      setCamelIndex((prevIndex) => prevIndex - 1);
    }
  };

  type PropsOfIcons = {
    image: string;
  };

  const GetBoosterIcon: React.FC<PropsOfIcons> = ({ image }) => {
    const src = preloadedImages[image];
    return src ? <img src={src.src} alt="" className="w-[230px] relative z-10" /> : null;
  };

  const GetCamelIcon: React.FC<PropsOfIcons> = ({ image }) => {
    const src = preloadedImages[image];
    return src ? <img src={src.src} alt="" className="relative z-10" /> : null;
  };

  const isCamelLocked = camels[camelIndex]?.lock;
  const isBoosterLocked = boosters[camelIndex]?.lock;

  return (
    <>
      <div className="relative">
        <motion.div
          key={camelIndex}
          initial={isFirstRender ? {} : { opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col mt-5 rounded-lg h-[200px] items-center justify-center"
          style={{ minHeight: '180px' }}
        >
          {mode === 'camel' ? (
            <div className="relative">
              <GetCamelIcon image={camels[camelIndex]?.image || ''} />
              {isCamelLocked && <Lock className="absolute top-[30%] left-[35%] z-[99]" />}
              <div
                className="absolute w-[200px] h-[160px] inset-0 left-[40%] top-[20%] blur-[60px]"
                style={{ backgroundColor: '#293B39' }}
              />
              <div
                className="absolute w-[100px] h-[120px] inset-0 top-[25%] blur-[70px] opacity-55 left-[5%]"
                style={{ backgroundColor: '#F8C17A' }}
              />
            </div>
          ) : (
            <div className="relative">
              <GetBoosterIcon image={boosters[camelIndex]?.image || ''} />
              {isBoosterLocked && <Lock className="absolute top-[20%] left-[30%] z-[99]" />}
              <div
                className="absolute w-[200px] h-[160px] inset-0 left-[40%] top-[20%] blur-[60px]"
                style={{ backgroundColor: '#293B39' }}
              />
              <div
                className="absolute w-[100px] h-[120px] inset-0 top-[25%] blur-[70px] opacity-55 left-[5%]"
                style={{ backgroundColor: '#F8C17A' }}
              />
            </div>
          )}
        </motion.div>

        <button
          className="absolute top-1/2 transform -translate-y-1/2 left-2 text-white h-[50px] w-[50px] rounded-xl border border-ColorsChapter2-250 z-[99] flex justify-center items-center rotate-180"
          onClick={handlePrevPage}
        >
          <Arrow/>
        </button>
        <button
          className="absolute top-1/2 transform -translate-y-1/2 right-2 text-white h-[50px] w-[50px] rounded-xl border border-ColorsChapter2-250 z-[99] flex justify-center items-center"
          onClick={handleNextPage}
        >
          <Arrow/>
        </button>
      </div>
      <div className="flex mt-3 items-center justify-center w-[200px]">
        {mode === 'camel' ? (
          <>
            {camels.map((_, index) => (
              <div
                key={index}
                className={`w-7 mx-1 rounded-full ${
                  camelIndex === index ? 'bg-black border h-[7px]' : 'h-[2px] bg-gray-400'
                }`}
              />
            ))}
          </>
        ) : (
          <>
            {boosters.map((_, index) => (
              <div
                key={index}
                className={`w-7 mx-1 rounded-full ${
                  camelIndex === index ? 'bg-black border h-[7px]' : 'h-[2px] bg-gray-400'
                }`}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default SelectCamelOrBooster;
