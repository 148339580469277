import { create } from 'zustand'
import { storeInterface } from '../models'

const useStore = create<storeInterface>((set, get) => ({
	currentCoin: 0,
	solDeposit: 0,
	avatar: '',
	username: '',
	identityId: '',
	skin: '',

	updateCurrentCoin: (currentCoin: number) => {
		set(() => ({
			currentCoin
		}))
	},

	updateUsername: (username: string) => {
		set(() => ({
			username
		}))
	},

	updateAvatar: (avatar: string) => {
		set(() => ({
			avatar
		}))
	},

	updateIdentityId: (identityId: string) => {
		set(() => ({
			identityId
		}))
	},

	updateSolDeposit: (solDeposit: number) => {
		set(() => ({
			solDeposit
		}))
	},

	updateSkin: (skin: string) => {
		set(() => ({
			skin
		}))
	},

	getSkin: () => {
		return get().skin;
  }

}))

export default useStore
