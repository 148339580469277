import { useEffect } from 'react'
import { useNecessary } from '../../../hooks/necessary'
import useApi from '../../../services/api/apiHandlerService'
import Coin from '../../images/coin.png'
import Loading from '../../images/loading.gif'
import { friendsData } from '../../models'
import useStore from '../../store/zustand'

type Props = {
	setReward: React.Dispatch<React.SetStateAction<number>>
	loading: boolean
	friends: friendsData[]
	referal: number
	getFriends: () => void
}

const InvitedTable:React.FC<Props> = ({setReward, loading, referal, friends, getFriends}) => {
	const api = useApi()

	const {identityId} = useNecessary()

	const {avatar} = useStore()
	const imageSrc = `data:image/png;base64,${avatar}`

	useEffect(() => {
		getFriends()
	}, [identityId])


	const DecodeImage = (file: File) => {
		if (file) {
			const imageSrc = `data:image/png;base64,${file}`
			return <img src={imageSrc} alt="" className='w-[50px] h-[50px] border-2 rounded-full border-ColorsChapter2-250' />
		}

		return null
	}

	return (
		<>
		<div className='text-white mt-10'>
			<p className='font-medium'>Friends <span className='font-light text-ColorsChapter2-500'>{referal}</span></p>
			{loading ? (
				<>
				<div className='w-full flex justify-center mt-2'>
			    <img src={Loading} alt="" className='w-[40px] h-[40px]' />
			  </div>
				</>
			) : friends.length === 0 ? (
				<div className='w-full text-center mt-4 p-5 text-sm rounded-xl' style={{background: `#181819`, border: '1px solid #28282A', color: `#6C717D`}}>
				You haven’t invited any friends yet
				</div>
			) : (
				<div className='overflow-y-scroll h-[250px] h-xs:h-[200px]'>
			{friends.map((friend, index) => (
				<div className='mt-4 border border-ColorsChapter2-350 flex justify-between items-center p-2 px-3 rounded-xl bg-ColorsChapter2-150' key={index}>

					<div className='flex gap-3 items-center'>
						{DecodeImage(friend.image)}

						<div className='flex flex-col gap-1'>
						<p className='text-ColorsChapter2-100 font-bold'>{friend.referral_username}</p>
						{friend.claimed ? (
							<p className='text-[8px] text-center bg-ColorsChapter2-450 w-[80px] p-[2px] text-ColorsChapter2-50 rounded-xl'>Invited</p>
						) : (
							<p className='text-[8px] text-center bg-ColorsChapter2-600 w-[80px] p-[2px] text-ColorsChapter2-50 rounded-xl'>waiting to claim</p>
						)}
					  </div>
					</div>

					

					<div className='flex gap-1 items-center'>
						<img src={Coin} alt="" className='w-[30px] h-[30px]' />
						<p className='font-medium'>+{friend.bonus}<span className='text-ColorsChapter2-100 font-light'> Points</span></p>
					</div>
				</div>

			  ))}
				</div>

			)}
			</div>
		</>
	)
}

export default InvitedTable