import { useNavigate } from 'react-router-dom'

// Interfaces for Telegram User and WebApp data
interface TelegramUser {
    id: number;
    username: string;
    first_name: string;
}

interface TelegramData {
    user: TelegramUser | null;
}

type TelegramHapticFeedback = {
    impactOccurred: (style: "light" | "medium" | "rigid" | "heavy" | "soft") => void;
    notificationOccurred: (type: "error" | "success" | "warning") => void;
}

interface StoryWidgetLink {
    url: string; // URL на Telegram-бота
    name: string; // Имя виджет-ссылки
}

interface TelegramWebApp {
    initDataUnsafe?: TelegramData;
    close: () => void;
    platform?: string;
    BackButton?: {
        show: () => void;
        hide: () => void;
        onClick: (callback: () => void) => void;
        offClick: (callback: () => void) => void;
    };
    HapticFeedback: TelegramHapticFeedback;
    shareToStory: (mediaUrl: string, params: { type: 'image' | 'video'; text?: string; widget_link?: StoryWidgetLink }) => Promise<void>;
}

const tg: TelegramWebApp = (window as any).Telegram.WebApp;

export function useTelegram() {
    const navigate = useNavigate();

    const onClose = () => {
        tg.close();
    };

    const shareToStory = async (mediaUrl: string, type: 'image' | 'video', caption: string, botUrl: string, botName: string): Promise<void> => {
        console.log('Media URL:', mediaUrl);
        console.log('Type:', type);
        console.log('Caption:', caption);
        console.log('Bot URL:', botUrl);
        console.log('Bot Name:', botName);
    
        if (tg.shareToStory) {
            const widgetLink: StoryWidgetLink = {
                url: botUrl,
                name: botName 
            };

            caption = `https://t.me/MetaCamelGame_bot?start=${userId}`;
            const text = caption;
            return tg.shareToStory(mediaUrl, { type, text, widget_link: widgetLink })
        } else {
            console.warn('Story sharing is not supported in this version.');
            throw new Error('Story sharing not supported');
        }
    };

    const userId = tg.initDataUnsafe?.user?.id || null;
    const user = tg.initDataUnsafe?.user?.username;
    const name = tg.initDataUnsafe?.user?.first_name;

    return {
        onClose,
        shareToStory, 
        tg,
        userId,
        user,
        name,
    };
}
