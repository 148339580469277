import localforage from 'localforage'
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/base/header'
import firstCamelIcon from '../components/images/camels/camelFirst.gif'
import SecondCamelIcon from '../components/images/camels/camelSecond.gif'
import ThirdCamelIcon from '../components/images/camels/camelThird.gif'
import Coin from '../components/images/coin.png'
import loadingGIF from '../components/images/loading.gif'
import { ReactComponent as TaskCompleteIcon } from '../components/images/taskpage/taskcompleted.svg'
import { getDataInterface } from '../components/models'
import useStore from '../components/store/zustand'
import '../components/ui/background.css'
import NavPanel from '../components/ui/NavPanel'
import useApi from '../services/api/apiHandlerService'
import { DailyReward } from '../types/typeDailyReward'
import { useIdentityHook } from "./identities/identity"
import { useTelegram } from './useTelegram'

interface NavContextType {
  identityId: string;
  getData: () => void
  images: HTMLImageElement[];
  imagesLoaded: boolean;
  setCamelLoaded: React.Dispatch<React.SetStateAction<boolean>>
  camelLoaded: boolean
  setFirstVisit: React.Dispatch<React.SetStateAction<boolean | null>>
  proccessLoading: number
  setCamelSkin: React.Dispatch<React.SetStateAction<string>>
  camelSkin: string
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
  isActive:boolean
  setLastClick: React.Dispatch<React.SetStateAction<Date | undefined>>
  lastClick: Date | undefined
  camelIcons: Record<string, string>
}

const NavContext = createContext<NavContextType | undefined>(undefined);

export const useNecessary = () => {

  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNecessary must be used within a NavPanelProvider');
  }
  return context;
};

interface NavPanelProviderProps {
  children: ReactNode;
}

const frameCount = 241;

const getImageDataURL = (img: HTMLImageElement): string => {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get canvas context');
  }
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL('image/png');
};

const preloadImages = (
  count: number,
  updateProgress: (progress: number) => void
): Promise<HTMLImageElement[]> => {
  const imagePromises: Promise<HTMLImageElement>[] = [];
  let loadedImages = 0;

  for (let i = 0; i < count; i++) {
    const src = `background/fon/fon_${i}.png`;

    imagePromises.push(
      localforage.getItem<string>(src).then((cachedImage) => {
        if (cachedImage) {
          const img = new Image();
          img.src = cachedImage;
          return new Promise<HTMLImageElement>((resolve) => {
            img.onload = () => {
              loadedImages += 1;
              const progress = Math.round((loadedImages / count) * 100);
              updateProgress(progress);
              resolve(img);
            };
          });
        } else {
          const img = new Image();
          img.src = src;
          return new Promise<HTMLImageElement>((resolve) => {
            img.onload = () => {
              loadedImages += 1;
              const progress = Math.round((loadedImages / count) * 100);
              updateProgress(progress);

              // Convert image to Data URL and store it in localForage
              const dataURL = getImageDataURL(img);
              localforage.setItem(src, dataURL);

              resolve(img);
            };
          });
        }
      })
    );
  }

  return Promise.all(imagePromises);
};

export const NavPanelProvider = ({ children }: NavPanelProviderProps) => {
  const api = useApi();
  const {signInAsync, signUpAsync, isUserExistAsync} = useIdentityHook();
  const {identityId, updateCurrentCoin} = useStore()
  const location = useLocation()
  const {userId, user, name, tg} = useTelegram()
  const once = useRef<boolean>(false)
  const [firstVisit, setFirstVisit] = useState<boolean | null>(null)
  const [dailyReward, setDailyReward] = useState<DailyReward>()
  const [dailyModal, setDailyModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [images, setImages] = useState<HTMLImageElement[]>([]);
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [camelLoaded, setCamelLoaded] = useState(false)
  const [dailyStatus, setDailyStatus] = useState('first')
  const navigate = useNavigate()
  const [proccessLoading, setProccessLoading] = useState<number>(0)
  const [camelSkin, setCamelSkin] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [lastClick, setLastClick] = useState<Date>() 

  const [preloadedImages, setPreloadedImages] = useState<Record<string, HTMLImageElement>>({});

const camelIcons: Record<string, string> = {
  'first_lvl_camel': firstCamelIcon,
  'second_lvl_camel': SecondCamelIcon,
  'third_lvl_camel': ThirdCamelIcon,
};

const preloadCamels = () => {
  const loadedImages: Record<string, HTMLImageElement> = {};
  Object.entries(camelIcons).forEach(([key, src]) => {
    const img = new Image();
    img.src = src;
    loadedImages[key] = img;
  });
  setPreloadedImages(loadedImages);
};



  const getDailyReward = async () => {
    if (!identityId) return
    const res = await api<DailyReward>({
      url: `/user-data/get-daily-reward`,
      method: 'GET',
      headers: {
        'x-user-id': identityId
      }
    })
    if (res) setDailyReward(res)
    if (res?.reward !== 0) setDailyModal(true)
  }

  const getData = async () => {
    if (!identityId) return
    const res = await api<getDataInterface>({
      url: `/user-data/get-data`,
      headers: {
        'x-user-id': identityId
      },
      method: 'GET'
    })
    if (res) {
      updateCurrentCoin(res?.current_coin)
    }
    console.log(res);
  }

  const claimDailyReward = async () => {
    setDailyModal(false)
    await api({
      url: `/user-data/claim-daily-reward`,
      headers: {
        'x-user-id': identityId
      },
      method: 'POST'
    })
    getData()
  }

  const init = async (): Promise<void> => {
    const telegramUserId = userId;
    const userExits = await isUserExistAsync(userId)
    if (userExits != null) {
      if(userExits){
        await signInAsync(userId)
      } else {
        if (tg.platform !== 'tdesktop')
        navigate('/howtoplay')
        setFirstVisit(true)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (identityId) {
      Promise.all([getDailyReward(), getData()])
      preloadCamels()
      setTimeout(() => {
        preloadImages(frameCount, setProccessLoading).then((loadedImages) => {
          setImages(loadedImages);
          setImagesLoaded(true)
        });
      }, 3000);
    }
  }, [identityId])

  useEffect(() => {
    if (proccessLoading >=  8) {
      setLoading(false)
    }
  }, [proccessLoading])
  

  useEffect(() => {
    if (!once.current) {
      init()
      once.current = true
    }
  }, [])

  
  if (loading) {
    return (
      <>
      <div className='background opacity-10'/>
      <div className="fixed inset-0 flex items-center justify-center z-50">
             <div className='w-full flex justify-center items-center'>
                <div className='max-w-[350px] w-full justify-center items-center'>
                  <img src={loadingGIF} alt="" />
                  <p className='text-4xl text-white font-medium text-center'>META CAMEL</p>
                  <p className='opacity-55 text-white text-base text-center
                   '>Please wait, data is loading</p>
                </div>
             </div>
      </div>
      </>
    )
  } 

  if (dailyReward?.reward !== 0 && dailyModal) {
    if (dailyStatus === 'first') {
      setTimeout(() => {
        setDailyStatus('second');
      }, 3000);
      return (
        <div className='h-[80vh] flex justify-center items-center'>
          <div className="w-full justify-center flex flex-col items-center">
            <p className="text-white text-[50px] font-bold text-center">{dailyReward?.day}</p>
            <p className="text-center" style={{ color: '#B7BBC4' }}>day check-in</p>
            <p className="text-white text-center max-w-[260px] mt-5">
              Pop into the app every day and snag some awesome bonuses!
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="background opacity-10" />
          <div className="fixed w-full flex items-center justify-center z-50 text-white h-full">
            <div className="w-full flex justify-center items-center mb-[40%]">
              <div className="max-w-[350px] w-full">
                <p className="text-2xl text-center font-bold">Your Daily Rewards</p>
                <div className="w-full px-2 py-2 pt-3 rounded-xl bg-ColorsChapter2-150 text-white relative border-ColorsChapter2-200 border-[2px] mt-10">
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full border border-ColorsChapter2-200">
                      <TaskCompleteIcon />
                    </div>
                    <p className="text-[15px] text-ColorsChapter2-500">
                      Earn Extra Rewards by Completing <br /> Tasks
                    </p>
                  </div>
                  <div
                    className="mt-4 p-2 border border-ColorsChapter2-400 rounded-xl flex justify-center gap-1 items-center"
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(12, 12, 13, 0.16) 0%, rgba(141, 102, 51, 0.56) 100%',
                    }}
                  >
                    <img src={Coin} alt="Coin" />
                    <p className="font-medium text-xl">+{dailyReward?.reward}</p>
                  </div>
                </div>
                <p className="mt-2 text-sm text-center" style={{ color: '#B7BBC4' }}>
                  Come back tomorrow to claim even more rewards!
                </p>
              </div>
            </div>
          </div>
          <button
            className="w-full max-w-[350px] text-black font-medium fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-ColorsChapter2-50 py-2 rounded-lg outline-none z-[999]"
            onClick={claimDailyReward}
          >
            Claim
          </button>
        </>
      );
    }
  }

    
  return (
      <NavContext.Provider value={{identityId, getData, images, imagesLoaded, camelLoaded, setCamelLoaded, setFirstVisit, proccessLoading, setCamelSkin, camelSkin, setIsActive, isActive, setLastClick, lastClick, camelIcons}}>
          <div className='background opacity-10'/>
          <div className='font-Montserrat'>
            {location.pathname !== '/tasks' && location.pathname !== '/howtoplay' && location.pathname !== '/friends' && location.pathname !== '/' && location.pathname !== '/game' && (
                <Header/>
            )} 
                {children}
                {location.pathname !== '/shop'&& location.pathname !== '/howtoplay' && <NavPanel />}
          </div>

      </NavContext.Provider>
  );
};
