import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Coin from '../components/images/coin.png'
import CamelIcon from '../components/images/gameimage/Camel.png'
import iconCoin from '../components/images/gameimage/frameBonus.png'
import modalBack from '../components/images/gameimage/menu.png'
import PalmIcon from '../components/images/gameimage/Palm.png'
import Union from '../components/images/gameimage/Union.png'
import Footer from '../components/images/gameimage/Vector.png'
import Poligon from '../components/images/Polygon.png'
import useStore from '../components/store/zustand'
import BackgroundGame from '../components/ui/gamepage/backgroundGame'
import Camel from '../components/ui/gamepage/camel'
import '../components/ui/gamepage/styleprogress.css'
import TapTextComponent from '../components/ui/gamepage/tap'
import '../components/ui/gamepage/tapbar.css'
import WaterProgressBar from '../components/ui/gamepage/waterProgress'
import { useNecessary } from '../hooks/necessary'
import { useTelegram } from '../hooks/useTelegram'
import useApi from "../services/api/apiHandlerService"
import TapbarIcon from './../components/images/tap-bar.png'

interface Camel {
  progress: number,
  speed: number,
  current_coin: number,
  stamina: number,
  current_water: number,
  regeneration: number,
  type: number
  camel: string
}

const Gamepage: React.FC = () => {
  const [stamina, setStamina] = useState<number>(0)
  const [water, setWater] = useState<number>(0)
  const [coin, setCoin] = useState<number>(0)
  const [regeneration, setRegeneration] = useState<number>(0)
  const [progressCount, setProgressCount] = useState<number>(0)
  const { imagesLoaded, camelLoaded, getData, proccessLoading, setCamelSkin } = useNecessary()
  const [modal, setModal] = useState<boolean>(false)
  const api = useApi()
  const navigate = useNavigate()
  const { tg } = useTelegram()
  const location = useLocation()
  const { userId } = useTelegram()
  const { updateSkin } = useStore()
  const camelId = location.state?.camelId
  const render = useRef(false)
  const tapRef = useRef<number>(0)
  const { isActive, setIsActive, setLastClick } = useNecessary()


  const claimReward = async () => {
    const res = await api({
      url: `/game/claim-reward?user_id=${userId}`,
      method: 'POST'
    })
    await loadDataAsync()
    getData()
    setModal(false)
  }

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'
    document.body.style.overflow = 'hidden'
    loadDataAsync()

    return () => {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
    }
  }, [])

  interface data {
    current_coin: number,
    coin_per_hour: number,
    speed: number,
    stamina: number,
    regeneration: number,
    current_water: number,
    current_path: number,
    camel: string
  }

  const loadDataAsync = async () => {
    const res = await api<data>({
      url: `/game?user_id=${userId}&camel_id=${camelId}`,
      method: 'GET',
    })
    if (res) {
      setStamina(res?.stamina)
      setWater(res.current_water)
      setRegeneration(res.regeneration)
      setCoin(res.current_coin)
      setProgressCount(res.current_path)
      setCamelSkin(res.camel)
    }
  }

  const updateProgress = useCallback(() => {
    const progressBar = document.querySelector('.progress-bar') as HTMLElement
    const progressCircle = document.getElementById('progress-circle') as HTMLElement

    if (progressBar && progressCircle) {
      progressBar.style.width = `${progressCount}%`
      progressCircle.textContent = `${Math.floor(progressCount)}%`
    }
  }, [progressCount])

  useEffect(() => {
    if (progressCount >= 100) {
      setModal(true)
    }
  }, [progressCount])

  const setPoints = async () => {
    const res = await api({
      url: `/game/set-points?user_id=${userId}&current_path=${progressCount}&coin=${coin}&current_water=${water}`,
      method: 'POST'
    })
  }

  const handleTap = () => {
    if (water > 0 && progressCount !== 100) {
      setCoin(prev => prev + 1)
      tapRef.current++
      setPoints()
      setProgressCount(progressCount + 0.01)
      updateProgress()
      setIsActive(true)
      const day = new Date
      setLastClick(day)
    }
  }



  const apiClaim = async () => {
    await api({
      url: `/game/claim?user_id=${userId}&current_path=${progressCount}&coin=1&current_water=${stamina}`,
      method: 'POST',
    })
  }

  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1)
    }

    if (tg?.BackButton) {
      tg.BackButton.show()
      tg.BackButton.onClick(handleBackButton)

      return () => {
        if (tg.BackButton) {
          tg.BackButton.offClick(handleBackButton)
          tg.BackButton.hide()
        }
      }
    }
  }, [tg?.BackButton])

  if (!imagesLoaded || !camelLoaded) {
    return (
      <>
        <div className='hidden'>
          <Camel />
        </div>
        <div className='background opacity-10' />
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className='w-full flex justify-center items-center'>
            <div className='max-w-[350px] w-full justify-center items-center'>
              <p className='text-4xl text-white font-medium text-center'>META CAMEL</p>
              <p className='opacity-55 text-white text-base text-center'>Please wait, data is loading</p>
              <div className="w-full bg-gray-200 bg-opacity-30 rounded-full h-4 mt-4">
                <div
                  className="h-4 rounded-full transition-all"

                  style={{
                    width: `${proccessLoading}%`, background: 'linear-gradient(82.14deg, #3C2E1D 0%, #D4A362 98.94%)'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <TapTextComponent onTap={handleTap} coin={coin} water={water} />
      <div className='relative' style={{ height: '100vh', width: '100vw' }}>
        <BackgroundGame />
        <div className='z-[9] absolute top-[55%] left-[30%]'>
          <Camel />
        </div>
        <div className='absolute top-0 w-full h-[50px]' style={{ background: "#101011" }}>
          <div className='relative'>
            <img src={Union} alt="" className='mt-11 w-full' />
            <div className='absolute text-white top-1 gap-0 w-full flex justify-center items-center'>
              <img src={Coin} alt="" className='w-[30px] h-[30px]' />
              <p className='text-sm'>{coin}</p>
            </div>
          </div>
          {/* Header */}
          <div className='absolute top-2 text-white w-full justify-center flex items-center gap-4'>
            <img src={CamelIcon} className='imageCamel' alt="" />
            <div className="progress-container">
              <div className="progress-bar" style={{ width: `${progressCount}%` }}>
                <div className="progress-circle">
                  {`${Math.floor(progressCount)}%`}
                </div>
              </div>
            </div>
            <img src={PalmIcon} className='imagePalm' alt="" />
          </div>
        </div>
        {/* Footer */}
        <div className='absolute bottom-[60px] w-full'>
          <img src={Footer} alt="" className='w-full' />
          <div className="absolute bottom-[25px] left-9 tap-bar-container">
            <img src={TapbarIcon} alt='tapbar' className='w-[245px] relative z-[1]' />
            <WaterProgressBar tap={tapRef.current} stamina={stamina} regeneration={regeneration} water={water} setWater={setWater} />
          </div>
          <img src={Poligon} alt="" className='w-[60px] absolute bottom-[10px] right-[5%]' />
        </div>
      </div>
      {modal && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 px-5 z-[99]">
          <div className='relative'>
            <img src={modalBack} alt="" className='w-full' />
            <div className='absolute bottom-[40%] w-full text-center text-white text-xl font-medium'>
              <p>Reward Bonus</p>
              <div className='relative mt-5 w-full flex justify-center'>
                <img src={iconCoin} alt="" />
                <div className='absolute mt-5 flex items-center'>
                  <img src={Coin} alt="" />
                  <p className='font-bold'>200</p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full flex flex-col justify-center items-center gap-3'>
            <button className='p-2 rounded-xl font-medium w-full max-w-[330px]'
              style={{ background: '#F8C17A' }} onClick={() => claimReward()}>
              Play Again
            </button>

            <button className='p-2 rounded-xl w-full max-w-[330px]' style={{ background: "#181819", border: "solid 1px #202022", color: '#F8C17A' }} onClick={() => navigate(-1)}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Gamepage