import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Standart from '../components/images/standart.png'
import useStore from '../components/store/zustand'
import SelectCamelOrBooster from '../components/ui/camelshop/selectCamelOrBooster'
import UpgradeInformer from '../components/ui/camelshop/upgradeInformer'
import useApi from '../services/api/apiHandlerService'
import { booster } from '../types/gameAttributes/booster'
import { camel } from '../types/gameAttributes/camel'
const CamelShoppage = () => {
	const api = useApi()
	const [mode, setMode] = useState<string>('camel')
	const [camels, setCamels] = useState<camel[]>([])
	const [boosters, setBoosters] = useState<booster[]>([])
	const { identityId } = useStore()
	const [camelIndex, setCamelIndex] = useState<number>(0)
	const navigate = useNavigate()

	useEffect(() => {
		setCamelIndex(0)
	}, [mode])

	const getCamels = async () => {
		if (!identityId) return
		const res = await api<camel[]>({
			method: 'GET',
			url: `/game/get-camels`,
			headers: {
				'x-user-id': identityId
			}
		})
		if (res) setCamels(res)
	}

	const getBoosters = async () => {
		if (!identityId) return
		const res = await api<booster[]>({
			method: 'GET',
			url: `/game/get-boosters`,
			headers: {
				'x-user-id': identityId
			}
		})
		if (res) setBoosters(res)
	}

	useEffect(() => {
		getCamels()
		getBoosters()
	}, [identityId])


	return (
		<>
			<div className='flex justify-center text-white'>
				<div className='max-w-[350px] w-full'>

					<div className='relative mt-10'>

						<div className='bg-ColorsChapter2-200 p-[2px] rounded-2xl' style={{ clipPath: 'polygon(100% 0, 100% 31%, 100% 83%, 68% 83%, 52% 100%, 0 100%, 0 0)' }}>

							<div className='w-full rounded-xl h-[285px] bg-ColorsChapter2-450' style={{ clipPath: 'polygon(100% 0, 100% 31%, 100% 83%, 68% 83%, 52% 100%, 0 100%, 0 0)' }}>

								<div className='flex w-full pt-1 gap-1 px-5 z-[999] relative'>

									<div className='border-ColorsChapter2-250 border border-opacity-35 w-full rounded-3xl p-1'>
										<button className={`w-1/2 p-[2px] rounded-2xl ${mode === 'camel' ? 'bg-ColorsChapter2-200 border border-ColorsChapter2-250 text-ColorsChapter2-50' : 'text-ColorsChapter2-100'}`} onClick={() => setMode('camel')}>Camel</button>

										<button className={`w-1/2 p-[2px] rounded-2xl ${mode === 'booster' ? 'bg-ColorsChapter2-200 text-ColorsChapter2-50 border border-ColorsChapter2-250' : 'text-ColorsChapter2-100'}`} onClick={() => setMode('booster')}>Boosters</button>
									</div>

								</div>

								<SelectCamelOrBooster mode={mode} camels={camels} boosters={boosters} setCamelIndex={setCamelIndex} camelIndex={camelIndex} />
							</div>

						</div>

						<div className='absolute rounded-xl p-[2px] top-[89%] w-full bg-ColorsChapter2-200' style={{ clipPath: 'polygon(54% 14%, 71% 0, 100% 0, 100% 53%, 100% 100%, 0 99%, 0 16%, 3% 14%)' }}>

							<div className='rounded-xl w-full px-2 py-3 h-auto bg-ColorsChapter2-150' style={{ clipPath: 'polygon(54% 14%, 71% 0, 100% 0, 100% 53%, 100% 100%, 0 99%, 0 16%, 3% 14%)' }}>

								<div className='w-full flex justify-end relative'>
									<img src={Standart} alt='standart' className='h-[20px] w-[95px]' />
								</div>

								<div className='mt-5'>
									<UpgradeInformer camels={camels} boosters={boosters} camelIndex={camelIndex} mode={mode} getBoosters={getBoosters} getCamels={getCamels} />
								</div>
							</div>


						</div>

					</div>
				</div>
			</div>
		</>
	)
}

export default CamelShoppage