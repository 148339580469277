// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/tap-bar.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tap-fill {
	height: 100%;
	background: linear-gradient(82.14deg, #D6FAF2 0%, #75F0D3 98.94%);
	border-radius: 100px;
	z-index: 0;
	width: 100%;
	transform-origin: left;
}

.tap-bar {
	position: absolute;
	width: 240px;
	height: 8px;
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-size: cover;
	z-index: 1;
}

.tap-count-container {
	position: absolute;
	bottom: 15vh;
	left: 50%;
	width: 90%;
	max-width: 157px;
	height: 20px;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2vh;
	color: white;
	z-index: 3;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/gamepage/tapbar.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,iEAAiE;CACjE,oBAAoB;CACpB,UAAU;CACV,WAAW;CACX,sBAAsB;AACvB;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,WAAW;CACX,yDAAiD;CACjD,sBAAsB;CACtB,UAAU;AACX;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,SAAS;CACT,UAAU;CACV,gBAAgB;CAChB,YAAY;CACZ,2BAA2B;CAC3B,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,cAAc;CACd,YAAY;CACZ,UAAU;AACX","sourcesContent":[".tap-fill {\n\theight: 100%;\n\tbackground: linear-gradient(82.14deg, #D6FAF2 0%, #75F0D3 98.94%);\n\tborder-radius: 100px;\n\tz-index: 0;\n\twidth: 100%;\n\ttransform-origin: left;\n}\n\n.tap-bar {\n\tposition: absolute;\n\twidth: 240px;\n\theight: 8px;\n\tbackground-image: url('../../images/tap-bar.png');\n\tbackground-size: cover;\n\tz-index: 1;\n}\n\n.tap-count-container {\n\tposition: absolute;\n\tbottom: 15vh;\n\tleft: 50%;\n\twidth: 90%;\n\tmax-width: 157px;\n\theight: 20px;\n\ttransform: translateX(-50%);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tfont-size: 2vh;\n\tcolor: white;\n\tz-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
