import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNecessary } from '../../../hooks/necessary'
import useApi from '../../../services/api/apiHandlerService'
import { camel } from '../../../types/gameAttributes/camel'
import { ReactComponent as Arrow } from '../../images/arrows.svg'
import loadingGIF from '../../images/loading.gif'
import Lock from '../../images/lock.svg'
import useStore from '../../store/zustand'

type Props = {
  image: number;
};

const SelectCamel = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const navigate = useNavigate();
  const { identityId } = useStore();
  const api = useApi();
  const [camels, setCamels] = useState<camel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { camelIconsArray, setCamelLoaded } = useNecessary();
  const [camelId, setCamelId] = useState<string>('');
  const GetCamelIcon = ({ image }: Props) => {
    const iconSrc = camelIconsArray[image].src;
    return iconSrc ? <img src={iconSrc} alt="CamelIcon" className="relative z-10" /> : null;
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const handleNextPage = () => {
    if (pageIndex < camels.length - 1) {
      setPageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageIndex !== 0) {
      setPageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const navigateToSelect = () => {
    navigate('/shop');
  };

  const getCamels = async () => {
    if (!identityId) return;
    try {
      setLoading(true);
      const res = await api<camel[]>({
        method: 'GET',
        url: `/game/get-camels`,
        headers: {
          'x-user-id': identityId,
        },
      });
      if (res) setCamels(res);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCamels();
  }, [identityId]);

  useEffect(() => {
    if (camels.length > 0) {
      setCamelId(camels[pageIndex]?.camel_id);
    }
  }, [pageIndex, camels]);

  const isCamelLocked = camels[pageIndex]?.lock;

  const startTheGame = () => {
    setCamelLoaded(false);
    navigate('/game', { state: { camelId } });
  };

  return (
    <>
      <div className="relative">
        {loading ? (
          <div
            className="flex items-center justify-center h-[310px] w-full"
            style={{ minHeight: '180px' }}
          >
            <img src={loadingGIF} alt="Loading" className="w-[40px] h-[40px]" />
          </div>
        ) : (
          camels.map((_, index) => (
            <motion.div
              key={index}
              initial={isFirstRender ? {} : { opacity: 0, y: 50 }}
              animate={{ opacity: pageIndex === index ? 1 : 0, y: pageIndex === index ? 0 : 50 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
              className={`flex flex-col mt-5 h-pc:mt-0 rounded-lg h-xs:h-[280px] h-pc:h-[150px] h-[400px] items-center justify-center ${pageIndex === index ? '' : 'hidden'}`}
              style={{ minHeight: '180px' }}
            >
              <div className="relative">
                <GetCamelIcon image={index} />
                {isCamelLocked && (
                  <img src={Lock} alt='Lock' className="absolute top-[30%] left-[35%] z-[99]" />
                )}
                <div
                  className="absolute w-[180px] h-[220px] inset-0 left-[40%] top-[15%] blur-[60px]"
                  style={{ backgroundColor: '#293B39' }}
                />
                <div
                  className="absolute w-[130px] h-[220px] inset-0 top-[15%] opacity-55 blur-[100px]"
                  style={{ backgroundColor: '#F8C17A' }}
                />
              </div>
            </motion.div>
          ))
        )}
        <button
          className="absolute top-1/2 transform -translate-y-1/2 left-2 text-white h-[50px] w-[50px] rounded-xl border border-ColorsChapter2-250 z-[99] flex justify-center items-center rotate-180"
          onClick={handlePrevPage}
        >
          <Arrow />
        </button>
        <button
          className="absolute top-1/2 transform -translate-y-1/2 right-2 text-white h-[50px] w-[50px] rounded-xl border border-ColorsChapter2-250 z-[99] flex justify-center items-center"
          onClick={handleNextPage}
        >
          <Arrow />
        </button>
      </div>

      <div className="flex mt-4 h-pc:mt-10 items-center justify-center">
        {camels.map((_, index) => (
          <div
            key={index}
            className={`w-7 mx-1 rounded-full ${pageIndex === index
              ? 'bg-black border h-[7px]'
              : 'h-[2px] bg-gray-400'
              }`}
          />
        ))}
      </div>

      <div className="flex flex-col h-pc:px-[5px] h-pc:absolute h-pc:bottom-[15vh] h-pc:w-full h-pc:left-0">
        <button
          className={`px-4 py-2 rounded-lg z-[99] mt-16 h-pc:mt-5 font-bold text-sm h-[40px] bg-ColorsChapter2-50 text-black ${isCamelLocked || !camelId
            ? 'opacity-55 cursor-not-allowed'
            : ''
            }`}
          onClick={() => !isCamelLocked && startTheGame()}
          disabled={isCamelLocked || !camelId}
        >
          Start
        </button>

        <button
          className="bg-ColorsChapter2-150 z-[99] border border-ColorsChapter2-350 text-ColorsChapter2-50 px-4 py-2 rounded-lg mt-3 font-bold h-[40px] text-sm"
          onClick={() => navigateToSelect()}
        >
          Select
        </button>
      </div>
    </>
  );
};

export default SelectCamel;
