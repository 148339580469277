import { CircularProgress } from '@mui/material'
import localforage from 'localforage'
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/base/header'
import firstCamelIcon from '../components/images/camels/camelFirst.gif'
import SecondCamelIcon from '../components/images/camels/camelSecond.gif'
import ThirdCamelIcon from '../components/images/camels/camelThird.gif'
import { getDataInterface } from '../components/models'
import useStore from '../components/store/zustand'
import '../components/ui/background.css'
import NavPanel from '../components/ui/NavPanel'
import useApi from '../services/api/apiHandlerService'
import { DailyReward } from '../types/typeDailyReward'
import { useIdentityHook } from "./identities/identity"
import { useTelegram } from './useTelegram'
interface NavContextType {
  identityId: string
  getData: () => void
  backgroundImages: HTMLImageElement[]
  imagesLoaded: boolean
  setCamelLoaded: React.Dispatch<React.SetStateAction<boolean>>
  camelLoaded: boolean
  proccessLoading: number
  setCamelSkin: React.Dispatch<React.SetStateAction<string>>
  camelSkin: string
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
  isActive: boolean
  setLastClick: React.Dispatch<React.SetStateAction<Date | undefined>>
  lastClick: Date | undefined
  camelIcons: Record<string, string>
  dailyReward: DailyReward | undefined
  camelIconsArray: HTMLImageElement[]
  setBackgroundImages: React.Dispatch<React.SetStateAction<HTMLImageElement[]>>
  setProccessLoading: React.Dispatch<React.SetStateAction<number>>
}

const NavContext = createContext<NavContextType | undefined>(undefined)

export const useNecessary = () => {

  const context = useContext(NavContext)

  if (context === undefined) {
    throw new Error('useNecessary must be used within a NavPanelProvider')
  }
  return context
}

interface NavPanelProviderProps {
  children: ReactNode
}


const getImageDataURL = (img: HTMLImageElement): string => {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('Failed to get canvas context')
  }
  ctx.drawImage(img, 0, 0)
  return canvas.toDataURL('image/png')
}

export const preloadBackground = (
  count: number,
  updateProgress: (progress: number) => void
): Promise<HTMLImageElement[]> => {
  const imagePromises: Promise<HTMLImageElement>[] = []
  let loadedImages = 0

  for (let i = 1; i < count; i++) {
    const src = `background/fon/bg_up_${i}.webp`

    imagePromises.push(
      localforage.getItem<string>(src).then((cachedImage) => {
        const img = new Image()
        img.src = cachedImage || src;

        return new Promise<HTMLImageElement>((resolve) => {
          img.onload = () => {
            loadedImages += 1
            const progress = Math.round((loadedImages / count) * 100)
            updateProgress(progress)

            if (!cachedImage) {
              const dataURL = getImageDataURL(img)
              localforage.setItem(src, dataURL) 
            }

            resolve(img); 
          };

          img.onerror = () => {
            console.error(`Failed to load image: ${src}`);
            resolve(img); 
          };
        });
      })
    )
  }

  return Promise.all(imagePromises);
};


export const NavPanelProvider = ({ children }: NavPanelProviderProps) => {
  const api = useApi()
  const { signInAsync, isUserExistAsync } = useIdentityHook()
  const { identityId, updateCurrentCoin } = useStore()
  const location = useLocation()
  const { userId, tg } = useTelegram()
  const once = useRef<boolean>(false)
  const [dailyReward, setDailyReward] = useState<DailyReward>()
  const [backgroundImages, setBackgroundImages] = useState<HTMLImageElement[]>([])
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [camelLoaded, setCamelLoaded] = useState(false)
  const navigate = useNavigate()
  const [proccessLoading, setProccessLoading] = useState<number>(0)
  const [camelSkin, setCamelSkin] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [lastClick, setLastClick] = useState<Date>()
  const [camelIconsArray, setCamelIconsArray] = useState<HTMLImageElement[]>([])
  const twiceOnce = useRef(false)

  const camelIcons: Record<string, string> = {
    'first_lvl_camel': firstCamelIcon,
    'second_lvl_camel': SecondCamelIcon,
    'third_lvl_camel': ThirdCamelIcon,
};

const preloadCamels = () => {
    const loadedImages: Record<string, HTMLImageElement> = {};
    const promises: Promise<void>[] = [];

    Object.entries(camelIcons).forEach(([key, src]) => {
        const img = new Image();
        img.src = src;

        const imgLoadPromise = new Promise<void>((resolve, reject) => {
            img.onload = () => {
                loadedImages[key] = img;
                resolve();
            };
            img.onerror = () => {
                console.error(`Failed to load image: ${src}`);
                resolve();
            };
        });

        promises.push(imgLoadPromise);
    });

    return Promise.all(promises).then(() => {
        const orderedImages = Object.keys(camelIcons).map(key => loadedImages[key]);
        setCamelIconsArray(orderedImages);
    });
  };

  const getDailyReward = async () => {
    if (!identityId) return
    const res = await api<DailyReward>({
      url: `/user-data/get-daily-reward`,
      method: 'GET',
      headers: {
        'x-user-id': identityId
      }
    })
    if (res) setDailyReward(res)
    if (res?.reward !== 0) navigate('/dailyreward')
  }

  const getData = async () => {
    if (!identityId) return
    const res = await api<getDataInterface>({
      url: `/user-data/get-data`,
      headers: {
        'x-user-id': identityId
      },
      method: 'GET'
    })
    if (res) {
      updateCurrentCoin(res?.current_coin)
    }
  }

  const loadData = async () => {
    {
     try {
        await Promise.all([getDailyReward(), getData()]);
        await preloadCamels()
        setImagesLoaded(true)
     } catch (error) {
       console.error('Error preloading images:', error);
     }
   }
 };

  const init = async (): Promise<void> => {
    const userExits = await isUserExistAsync(userId)
    if (userExits != null) {
      if (userExits) {
        await signInAsync(userId)
      } else {
        navigate('/howtoplay')
      }
    }
  }

  useEffect(() => {
    if (!once.current && userId) {
      init()
      once.current = true
    }
  }, [userId])

  useEffect(() => {
    if (identityId) { 
      if (!twiceOnce.current) {
          loadData()
          twiceOnce.current = true
      }
    }
  }, [identityId])

  if (!imagesLoaded) {
    return (
      <>
        <div className='background2' />
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className='w-full flex justify-center items-center'>
            <div className='max-w-[350px] w-full justify-center items-center'>
              <p className='text-4xl text-white font-medium text-center'>Meta Camel Loading...</p>
              <div className='flex justify-center mt-5'>
              <CircularProgress 
                sx={{ color: 'white' }} 
              />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <NavContext.Provider value={{ identityId, getData, backgroundImages, imagesLoaded, camelLoaded, setCamelLoaded, proccessLoading, setCamelSkin, camelSkin, setIsActive, isActive, setLastClick, lastClick, camelIcons, dailyReward, camelIconsArray, setBackgroundImages, setProccessLoading }}>
      <div className='background opacity-10' />
      <div>
        {['/tasks', '/howtoplay', '/friends', '/', '/game', '/dailyreward'].includes(location.pathname) ? null : <Header />}
        {children}
        {!['/shop', '/howtoplay', '/dailyreward'].includes(location.pathname) && <NavPanel />}
      </div>
    </NavContext.Provider>
  )
}
