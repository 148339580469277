import { useEffect, useState } from 'react'
import { useNecessary } from '../../../hooks/necessary'
import useApi from '../../../services/api/apiHandlerService'
import { ReactComponent as Instance } from '../../images/boostpage/Instance.svg'
import { ReactComponent as PointBonus } from '../../images/boostpage/PointsBonus.svg'
import { ReactComponent as Water } from '../../images/boostpage/regeneration.svg'
import { ReactComponent as Speed } from '../../images/boostpage/speed.svg'
import { ReactComponent as Stamina } from '../../images/boostpage/stamina.svg'
import Coin from '../../images/coin.png'
import Loading from '../../images/loading.gif'
import { boost } from '../../models'

interface StarsProps {
  level: number
}

const Stars: React.FC<StarsProps> = ({ level }) => (
  <>
    {[...Array(10)].map((_, i) => (
      <div
        key={i}
        className={`w-[6px] rounded-[3px] rotate-6 h-[16px] ${
          i < level
            ? 'bg-gradient-to-r from-[#D6FAF2] via-[#75F0D3] to-[#75F0D3]'
            : 'bg-ColorsChapter2-200'
        }`}
      />
    ))}
  </>
)

const GetIcon = (icon: string) => {
  switch (icon) {
    case 'Speed':
      return <Speed className='w-[85px] h-[85px] z-1 relative' />
    case 'Regeneration':
      return <Water className='w-[85px] h-[85px] z-1 relative' />
    case 'Point bonus':
      return <PointBonus className='w-[85px] h-[85px] z-1 relative flex items-center' />
    case 'Energy':
      return <Stamina className='w-[85px] h-[85px] z-1 relative' />
    case 'Instant energy':
      return <Instance className='w-[85px] h-[85px]' />
  }
}

const BoostTable = () => {
  const api = useApi()
  const { identityId } = useNecessary()
  const [upgrades, setUpgrades] = useState<boost[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { getData } = useNecessary()

  const getUpgrades = async () => {
    if (!identityId) return
    const res = await api<boost[]>({
      url: '/boost/upgrade',
      method: 'GET',
      headers: {
        'x-user-id': identityId,
      },
    })
    setLoading(false)
    if (res) {
      setUpgrades(res)
    }
  }

  const buyUpgrades = async (boostId: string) => {
    if (!identityId) return
    await api({
      url: `/boost/buy-upgrade?boost_id=${boostId}`,
      method: 'POST',
      headers: {
        'x-user-id': identityId,
      },
    })
    getUpgrades()
    getData()
  }

  useEffect(() => {
    if (identityId) {
      getUpgrades()
    }
  }, [identityId])

  return (
    <div className='flex flex-col gap-3 h-full text-white'>
      {loading ? (
        <div className='w-full flex justify-center mt-2'>
          <img src={Loading} alt='Loading' className='w-[40px] h-[40px]' />
        </div>
      ) : (
        <div className='flex flex-col'>
          {upgrades.length > 0 ? (
            upgrades.map((upgrade, index) => {
              if (!upgrade || !upgrade.type) return null
              return upgrade.type === 'Instant energy' ? (
                <div key={index} className='h-[130px] rounded-3xl flex flex-col justify-end relative'>
                  <div className='absolute inset-0 blur-lg bg-custom-gradient bg-opacity-65 h-6'></div>
                  <div className='pb-[0.6px] z-10 border-t-2 border-x rounded-2xl border-ColorsChapter2-300 bg-white tbg-opacity-35'>
                    <div className='w-full bg-ColorsChapter2-150 rounded-2xl p-2 py-4 border-opacity-45 flex items-center justify-between'>
                      <div className='flex gap-2'>
                        {GetIcon(upgrade.type)}
                        <div className='text-left'>
                          <p className='text-lg custom-sm:text-base'>{upgrade.type}</p>
                          <p className='text-[10px] opacity-80'>{upgrade.description}</p>
                        </div>
                      </div>
                      <div className='flex justify-end items-end'>
                        <button
                          className='bg-ColorsChapter2-50 p-1 h-[35px] px-4 rounded-lg flex items-center justify-center text-black font-medium mr-[9px]'
                          onClick={() => buyUpgrades(upgrade.boost_id)}
                        >
                          <img src={Coin} alt='Coin' className='w-[25px] h-[25px]' />
                          {upgrade.price !== 0 && (
                            <p className='text-center cursor-pointer h-pc:text-[13px]'>{upgrade.price}</p>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={index} className='bg-ColorsChapter2-150 border border-ColorsChapter2-200 rounded-2xl custom-sm:py-1 p-2 flex items-center mt-3'>
                  <div className='flex gap-2'>
                    {GetIcon(upgrade.type)}
                    <div className='text-left'>
                      <p className='text-lg custom-sm:text-base'>{upgrade.type}</p>
                      <p className='text-[10px] opacity-80 w-[230px]'>{upgrade.description}</p>
                      <div className='mt-1'>
                        <div className='flex gap-1 items-center'>
                          <div className='flex gap-2 items-center w-full justify-between'>
                            <div className='flex gap-[5px] border-2 border-ColorsChapter2-200 p-1 px-1 rounded'>
                              <Stars level={upgrade.lvl} />
                            </div>
                            <button
                              className='bg-ColorsChapter2-50 p-1 px-4 rounded-lg flex items-center justify-center text-black font-medium w-[100px]'
                              onClick={() => buyUpgrades(upgrade.boost_id)}
                            >
                              <img src={Coin} alt='Coin' className='w-[25px] h-[25px]' />
                              {upgrade.price !== 0 && (
                                <p className='text-center cursor-pointer h-pc:text-[13px]'>{upgrade.price}</p>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p>No upgrades available.</p>
          )}
        </div>
      )}
    </div>
  )
}

export default BoostTable
