// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
	z-index: 1;
	width: 200px;
	height: 14px;
	border: 2px solid #202022; 
	border-radius: 24px;
}

.progress-bar {
	height: 70%;
	margin-left: 2px;
	top: 15%;
	background: linear-gradient(82.14deg, #3C2E1D 0%, #D4A362 98.94%);
	border-radius: 8px;
	position: relative;
	width: 0;
}

.progress-circle {
	width: 35px;
	height: 35px;
	background-color: #000000;
	border: 1px solid #202022;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: #AC8450;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/gamepage/styleprogress.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,YAAY;CACZ,YAAY;CACZ,yBAAyB;CACzB,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,gBAAgB;CAChB,QAAQ;CACR,iEAAiE;CACjE,kBAAkB;CAClB,kBAAkB;CAClB,QAAQ;AACT;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,yBAAyB;CACzB,yBAAyB;CACzB,kBAAkB;CAClB,kBAAkB;CAClB,QAAQ;CACR,QAAQ;CACR,+BAA+B;CAC/B,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,eAAe;CACf,cAAc;CACd,sCAAsC;AACvC","sourcesContent":[".progress-container {\n\tz-index: 1;\n\twidth: 200px;\n\theight: 14px;\n\tborder: 2px solid #202022; \n\tborder-radius: 24px;\n}\n\n.progress-bar {\n\theight: 70%;\n\tmargin-left: 2px;\n\ttop: 15%;\n\tbackground: linear-gradient(82.14deg, #3C2E1D 0%, #D4A362 98.94%);\n\tborder-radius: 8px;\n\tposition: relative;\n\twidth: 0;\n}\n\n.progress-circle {\n\twidth: 35px;\n\theight: 35px;\n\tbackground-color: #000000;\n\tborder: 1px solid #202022;\n\tborder-radius: 50%;\n\tposition: absolute;\n\ttop: 50%;\n\tright: 0;\n\ttransform: translate(50%, -50%);\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tfont-size: 12px;\n\tcolor: #AC8450;\n\tbox-shadow: 0 0 5px rgba(0, 0, 0, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
