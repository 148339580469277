import { Player } from '@remotion/player'
import { useEffect, useMemo, useState } from 'react'
import { useNecessary } from '../../../hooks/necessary'


let frameCount = 1;
const frameDuration = 1000 / 20;

const preloadImages = async (start: number, end: number, skin: string): Promise<HTMLImageElement[]> => {
  const imagePromises: Promise<HTMLImageElement>[] = [];
  
  for (let i = start; i <= end; i++) {
    const img = new Image();
    img.src = `${skin}${i}.png`;
    imagePromises.push(new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = () => reject(`Failed to load image: ${img.src}`);
    }));
  }

  try {
    return await Promise.all(imagePromises);
  } catch (error) {
    console.error('Error loading images:', error);
    return [];
  }
};




const loadCamelSkin = (camel: string, mode: string): string => {
  const camelMap: Record<string, string> = {
    "carpet_v5_": `carpet_v5_${mode}/carpet_v5_`,
    "carpet_v5_drone1_": `carpet_v5_${mode}_drone1/carpet_v5_drone1_`,
    "carpet_v5_drone2_": `carpet_v5_${mode}_drone2/carpet_v5_drone2_`,
    "carpet_v5_drone3_": `carpet_v5_${mode}_drone3/carpet_v5_drone3_`,
    "carpet_v4_": `carpet_v4_${mode}/carpet_v4_`,
    "carpet_v4_drone1_": `carpet_v4_${mode}_drone1/carpet_v4_drone1_`,
    "carpet_v4_drone2_": `carpet_v4_${mode}_drone2/carpet_v4_drone2_`,
    "carpet_v4_drone3_": `carpet_v4_${mode}_drone3/carpet_v4_drone3_`,
    "carpet_v3_": `carpet_v3_${mode}/carpet_v3_`,
    "carpet_v3_drone1_": `carpet_v3_${mode}_drone1/carpet_v3_drone1_`,
    "carpet_v3_drone2_": `carpet_v3_${mode}_drone2/carpet_v3_drone2_`,
    "carpet_v3_drone3_": `carpet_v3_${mode}_drone3/carpet_v3_drone3_`,
    "carpet_v2_": `carpet_v2_${mode}/carpet_v2_`,
    "carpet_v2_drone1_": `carpet_v2_${mode}_drone1/carpet_v2_drone1_`,
    "carpet_v2_drone2_": `carpet_v2_${mode}_drone2/carpet_v2_drone2_`,
    "carpet_v2_drone3_": `carpet_v2_${mode}_drone3/carpet_v2_drone3_`,
    "carpet_v1_": `carpet_v1_${mode}/carpet_v1_`,
    "carpet_v1_drone1_": `carpet_v1_${mode}_drone1/carpet_v1_drone1_`,
    "carpet_v1_drone2_": `carpet_v1_${mode}_drone2/carpet_v1_drone2_`,
    "carpet_v1_drone3_": `carpet_v1_${mode}_drone3/carpet_v1_drone3_`,
  };

  const path = camelMap[camel];

  if (path) {
    return `camel/${mode}/${path}`;
  } else {
    console.error('Unknown camel type:', camel);
    return ''; 
  }
};

const YourComponent: React.FC = () => {
  const [frame, setFrame] = useState<number>(0);
  const [images, setImages] = useState<HTMLImageElement[]>([]);
  const [imagesStart, setImagesStart] = useState<HTMLImageElement[]>([])
  const [imagesEnd, setImagesEnd] = useState<HTMLImageElement[]>([])
  const [loading, setLoading] = useState<boolean>(true);
  const { setCamelLoaded, camelSkin, setCamelSkin,isActive, setIsActive, lastClick} = useNecessary();
  const [isStart, setIsStart] = useState<boolean>(true)
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const resForLoop = loadCamelSkin(camelSkin, 'loop');
        const resForStart = loadCamelSkin(camelSkin, 'start')
        const resForEnd = loadCamelSkin(camelSkin, 'end')

        const loadedStart = await preloadImages(0, 13, resForStart);
 
        const loadedImages = await preloadImages(14, 28, resForLoop);

        const loadedEnd = await preloadImages(29, 43, resForEnd);

        setImagesStart(loadedStart)
        setImages(loadedImages);
        setImagesEnd(loadedEnd)
        
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setCamelLoaded(true);
      }
    };

    fetchImages();

    let startTime: number | null = null;
    let animationFrameId: number;

    const animate = (timestamp: number) => {
      if (startTime === null) startTime = timestamp;
      const elapsed = timestamp - startTime;

      if (elapsed > frameDuration) {
        setFrame((prevFrame) => (prevFrame + 1) % frameCount);
        startTime = timestamp;
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrameId);
  }, [camelSkin, setCamelLoaded]);



  const imageUrl = useMemo(() => {
    if (isStart) {
      if (isActive) {
   
        
        if (frame === 12) {
          setIsStart(false)
        }
        
        return imagesStart[frame]?.src; 
      }
      return imagesStart[0]?.src;
    } else {
      if (!isActive) {
        frameCount = 15
        if (frame === 14) {
          setIsStart(true)
          return imagesStart[0]?.src
        }
       
        return imagesEnd[frame]?.src
      }
      if (frame === 14) {
        return images[frame]?.src; 
      }
      if (lastClick) {
        const now = new Date(); 
        const timeDiff = now.getTime() - lastClick.getTime(); 
        
        
        if (timeDiff > 1000) {
          setIsActive(false)
          console.log(timeDiff);
        } 
      }
      return images[frame]?.src;
    
    }
  }, [isStart, frame, imagesStart, images]);

  useEffect(() => {
    if (isActive && isStart) {
      frameCount = 14
      const interval = setInterval(() => {
        setFrame((prevFrame) => (prevFrame < 14 ? prevFrame + 1 : prevFrame));
      }, 100); 

    
    
      return () => clearInterval(interval);
    }
  }, [isActive, isStart, lastClick]);



  useEffect(() => {
    if (isActive) {
      setIsStart(true)
    }
  }, [isActive])
  
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        display: loading ? 'flex' : 'block',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <span></span>
      ) : (
        <img
          src={imageUrl}
          alt="Frame"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      )}
    </div>
  );
};

 
const Camel: React.FC = () => {
  interface Sizes {
    width: number;
    height: number;
    left: string;
    top: string;
  }

  const [compositionSize, setCompositionSize] = useState<Sizes>({
    width: 340,
    height: 332,
    top: '-10px',
    left: '-60px',
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 375) {
        setCompositionSize({
          width: 285,
          height: 320,
          top: '-70px',
          left: '-20px',
        });
      } else if (screenWidth <= 390) {
        setCompositionSize({
          width: 310,
          height: 344,
          top: '-65px',
          left: '-35px',
        });
      } else if (screenWidth <= 414) {
        setCompositionSize({
          width: 334,
          height: 336,
          top: '0',
          left: '-100px',
        });
      } else {
        setCompositionSize({
          width: 340,
          height: 332,
          top: '-10px',
          left: '-60px',
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: compositionSize.width,
        height: compositionSize.height,
        overflow: 'hidden',
        left: compositionSize.left,
        top: compositionSize.top,
      }}
    >
      <Player
        component={YourComponent}
        durationInFrames={frameCount}
        compositionWidth={compositionSize.width}
        compositionHeight={compositionSize.height}
        fps={10}
        loop
        autoPlay
      />
    </div>
  );
};

export default Camel;
