import { Route, Routes } from 'react-router-dom'
import { NavPanelProvider } from '../hooks/necessary'
import Boostpage from '../pages/boostpage'
import CamelShoppage from '../pages/camelshoppage'
import Desktop from '../pages/desktop'
import Friendspage from '../pages/friendspage'
import Gamepage from '../pages/gamepage'
import Homepage from '../pages/homepage'
import HowToPlayPage from '../pages/howtoplaypage'
import RegistrationPage from '../pages/registrationpage'
import Taskspage from '../pages/taskspage'

const Router = () => {
	return (
		<>
		<Routes>
			  <Route path='/' element={
					  <NavPanelProvider>
						  <Homepage/>
					  </NavPanelProvider>
				  }/>
				<Route path='/desktop' element={
						  <Desktop/>
				  }/>
				<Route path='/game' element={
					  <NavPanelProvider>
						  <Gamepage/>
					  </NavPanelProvider>
				  }/>
				<Route path='/howtoplay' element={
					  <NavPanelProvider>
						  <HowToPlayPage/>
					  </NavPanelProvider>
				  }/>
				<Route path='/tasks' element={
					<NavPanelProvider>
						  <Taskspage/>
					</NavPanelProvider>
				  }/>
				<Route path='/friends' element={
					<NavPanelProvider>
						  <Friendspage/>
					</NavPanelProvider>
				  }/>
				<Route path='/boost' element={
					<NavPanelProvider>
						  <Boostpage/>
					</NavPanelProvider>
				  }/>
			  <Route path='/shop' element={
					<NavPanelProvider>
						  <CamelShoppage/>
					</NavPanelProvider>
				  }/>
				<Route path='/signup' element={
						  <RegistrationPage/>
				  }/>
		</Routes>
		</>
	)
}

export default Router