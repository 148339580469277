import { useRef } from 'react'
import { ReactComponent as Camera } from '../../images/camera.svg'
import { ReactComponent as Pen } from '../../images/pen-line.svg'

type Props = {
	src: string,
	setSrc: React.Dispatch<any>
	setError: React.Dispatch<React.SetStateAction<string | null>>
}

const Welcome:React.FC<Props> = ({src, setSrc, setError}) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			
			if (!file.type.startsWith('image/')) {
				setError('Please select a valid image file.');
				return;
			}
			
			setError(null);

			const reader = new FileReader();
			
			reader.onloadend = () => {
				if (typeof reader.result === 'string') {
					setSrc(reader.result);
				}
			};
			
			reader.readAsDataURL(file);
		}
	};

	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};
	
	return (
		<>
			<div className='w-full flex flex-col justify-center items-center text-white'>
				<p className='text-3xl font-bold'>Welcome Aboard</p>
				<p className='text-sm text-ColorsChapter2-100 mt-3'>Enter your nickname and enjoy</p>
				
					{src ? (
						<>
						<div className='w-[100px] h-[100px] bg-ColorsChapter2-150 flex justify-center items-center rounded-full mt-5'>
					  <img src={src} alt="" className='w-[100px] h-[100px] rounded-full' />
						</div>
						</>
					) : (
						<>
						<div className='w-[100px] h-[100px] bg-ColorsChapter2-150 flex justify-center items-center rounded-full mt-5 relative'>
						<Camera className='w-[30px] h-[30px]'/>
						<div className='absolute bottom-[-15px] border-ColorsChapter2-200 border bg-ColorsChapter2-150 w-[70px] text-[12px] h-[30px] flex items-center justify-center rounded-3xl gap-1' onClick={handleButtonClick}>
						<Pen className='w-[15px] h-[15px]'/>
						<p className='font-medium'>Add</p>
					  </div>
						</div>
					  </>
					)}
					
				</div>

			<input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        />
		</>
	)
}

export default Welcome