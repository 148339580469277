// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}

.avatar-preview {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/registrationpage/cropStyle.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["\n.avatar-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  overflow: hidden;\n}\n\n.avatar-preview {\n  border-radius: 50%;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
