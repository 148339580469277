import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useApi from '../../../services/api/apiHandlerService'
import { camel } from '../../../types/gameAttributes/camel'
import { ReactComponent as Arrow } from '../../images/arrows.svg'

import { useNecessary } from '../../../hooks/necessary'
import loadingGIF from '../../images/loading.gif'
import { ReactComponent as Lock } from '../../images/lock.svg'
import useStore from '../../store/zustand'

type Props = {
  image: string;
};





const SelectCamel = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const navigate = useNavigate();
  const { identityId } = useStore();
  const api = useApi();
  const [camels, setCamels] = useState<camel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {camelIcons} = useNecessary()
  const [camelId, setCamelId] = useState<string>('')

  const GetCamelIcon = ({ image }: Props) => {
    const iconSrc = camelIcons[image] || '';
    return iconSrc ? <img src={iconSrc} alt="" className="relative z-10" /> : null;
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);


  const handleNextPage = () => {
    if (pageIndex < camels.length - 1) {
      setPageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageIndex !== 0) {
      setPageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const navigateToSelect = () => {
    navigate('/shop');
  };

  const getCamels = async () => {
    if (!identityId) return;
    try {
      setLoading(true);
      const res = await api<camel[]>({
        method: 'GET',
        url: `/game/get-camels`,
        headers: {
          'x-user-id': identityId,
        },
      });
      if (res) setCamels(res);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCamels();
  }, [identityId]);

  useEffect(() => {
    if (camels.length > 0) {
      setCamelId(camels[pageIndex]?.camel_id || '');
    }
  }, [pageIndex, camels]);

  const isCamelLocked = camels[pageIndex]?.lock;

  return (
    <>
      <div className="relative">
        <motion.div
          key={pageIndex}
          initial={isFirstRender ? {} : { opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col mt-5 rounded-lg h-xs:h-[280px] h-[400px] items-center justify-center"
          style={{ minHeight: '180px' }}
        >
          {loading ? (
            <img src={loadingGIF} alt="" className="w-[40px] h-[40px]" />
          ) : (
            <>
              <div className="relative">
                <GetCamelIcon image={camels[pageIndex]?.image} />
                {isCamelLocked && (
                  <Lock className="absolute top-[30%] left-[35%] z-[99]" />
                )}
                <div
                  className="absolute w-[180px] h-[220px] inset-0 left-[40%] top-[15%] blur-[60px]"
                  style={{ backgroundColor: '#293B39' }}
                />
                <div
                  className="absolute w-[130px] h-[220px] inset-0 top-[15%] opacity-55 blur-[100px]"
                  style={{ backgroundColor: '#F8C17A' }}
                />
              </div>
            </>
          )}
        </motion.div>

        <button
          className="absolute top-1/2 transform -translate-y-1/2 left-0 text-white h-[50px] w-[50px] rounded-xl border border-ColorsChapter2-250 z-[99] flex justify-center items-center rotate-180"
          onClick={handlePrevPage}
        >
          <Arrow />
        </button>
        <button
          className="absolute top-1/2 transform -translate-y-1/2 right-0 text-white h-[50px] w-[50px] rounded-xl border border-ColorsChapter2-250 z-[99] flex justify-center items-center"
          onClick={handleNextPage}
        >
          <Arrow />
        </button>
      </div>

      <div className="flex mt-4 items-center justify-center">
        {camels.map((_, index) => (
          <div
            key={index}
            className={`w-7 mx-1 rounded-full ${
              pageIndex === index
                ? 'bg-black border h-[7px]'
                : 'h-[2px] bg-gray-400'
            }`}
          />
        ))}
      </div>

      <div className="flex flex-col">
        <button
          className={`px-4 py-2 rounded-lg mt-16 font-bold text-sm h-[40px] ${
            isCamelLocked
              ? 'bg-ColorsChapter2-50 text-black opacity-55 cursor-not-allowed'
              : 'bg-ColorsChapter2-50 text-black'
          }`}
          onClick={() => !isCamelLocked && navigate('/game', {state: {camelId}})}
          disabled={isCamelLocked}
        >
          Start
        </button>

        <button
          className="bg-ColorsChapter2-150 border border-ColorsChapter2-350 text-ColorsChapter2-50 px-4 py-2 rounded-lg mt-3 font-bold h-[40px] text-sm"
          onClick={() => navigateToSelect()}
        >
          Select
        </button>
      </div>
    </>
  );
};

export default SelectCamel;
