import { useEffect, useState } from 'react'
import { abbreviateNumber } from '../../../hooks/convertNumber'
import { useNecessary } from '../../../hooks/necessary'
import useApi from '../../../services/api/apiHandlerService'
import pointsbonus from '../../images/boostpage/pointsbonus.png'
import regeneration from '../../images/boostpage/regeneration.png'
import speedCamel from '../../images/boostpage/speedCamel.png'
import waterCamel from '../../images/boostpage/waterCamel.png'
import Coin from '../../images/coin.png'
import Loading from '../../images/loading.gif'
import { boost } from '../../models'

interface StarsProps {
	level: number
}

const Stars: React.FC<StarsProps> = ({ level }) => (
	<>
        {[...Array(16)].map((_, i) => (
                <div key={i} className={`w-[6px] rounded-[3px] rotate-6 h-[16px] ${i < level ? 'bg-gradient-to-r from-[#D6FAF2] via-[#75F0D3] to-[#75F0D3]' : 'bg-ColorsChapter2-200'}`} />
        ))}
	</>
)

    const GetIcon = (icon: string) => {
        switch (icon) {
            case 'Speed': return <img src={speedCamel} alt="" className='w-[70px] h-[70px] z-1 relative' />
            case 'Regeneration': return <img src={pointsbonus} alt="" className='w-[70px] h-[70px] z-1 relative p-1 mt-2' />
            case 'PointBonus': return <img src={regeneration} alt="" className='w-[70px] h-[70px] z-1 relative p-1 mt-2' />
            case 'Stamina': return <img src={waterCamel} alt="" className='w-[70px] h-[70px] z-1 relative p-1 mt-2' />
        }
    }


const BoostTable = () => {
    const api = useApi()
    const { identityId } = useNecessary()
    const [upgrades, setUpgrades] = useState<boost[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const {getData} = useNecessary()

    const getUpgrades = async () => {
        if (!identityId) return
        const res = await api<boost[]>({
            url: '/boost',
            method: 'GET',
            headers: {
                'x-user-id': identityId
            }
        })
        setLoading(false)
        if (res) {
            setUpgrades(res)
        }
    }

	const buyUpgrades = async (boostId:string) => {
			if (!identityId) return
			const res = await api<boost[]>({
					url: `/boost/buy-boost?boost_id=${boostId}`,
					method: 'POST',
					headers: {
							'x-user-id': identityId
					}
			})
		getUpgrades()
        getData()
	}

    useEffect(() => {
        getUpgrades()
    }, [identityId])

    return (
        <div className='flex flex-col gap-3 h-full text-white'>
            {loading ? (
                <>
                <div className='w-full flex justify-center mt-2'>
			    <img src={Loading} alt="" className='w-[40px] h-[40px]' />
			    </div>
              </>
            ) : (
                <div className='h-full h-xs:h-[85%] overflow-y-scroll flex flex-col gap-3'>
                {upgrades.map((upgrade, index) => (
                    <div className='bg-ColorsChapter2-150 border border-ColorsChapter2-200 rounded-2xl custom-sm:py-1 px-2 py-2 flex justify-between' key={index}>
                        <div className='flex gap-2'>
                        {GetIcon(upgrade.type)}
                        <div className='text-left'>
                            <p className='text-lg custom-sm:text-base'>{upgrade.type}</p>
                            <p className='text-[10px] opacity-80'>{upgrade.description}</p>
                            <div className='mt-1'>
                                <div className='flex gap-1 items-center'>
                                    <div className='flex gap-2 items-center'>
                                        <div className='flex gap-[3px] border-2 border-ColorsChapter2-200 p-1 px-2 rounded'>
                                        <Stars level={upgrade.lvl} />
                                        </div>
    
                                        <div className='bg-ColorsChapter2-50 p-1 w-[90px] rounded-lg flex items-center justify-center pr-4 text-black font-medium'>
                                           <img src={Coin} alt="" className='w-[25px] h-[25px]' />
                                           {upgrade.price !== 0 && (
                                            <>
                                            <p className='text-center w-[22px] cursor-pointer' onClick={() => {buyUpgrades(upgrade.boost_id)}}>{abbreviateNumber(upgrade.price)}</p>
                                            </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                ))}
                </div>
            )}
        </div>
    )
}

export default BoostTable
