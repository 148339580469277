import BoostTable from '../components/ui/boostpage/boostTable'

const Boostpage = () => {

	return (
		<>
			<div className='flex justify-center'>
				<div className='max-w-[350px] w-full'>
					<p className='text-white text-center mt-5 text-3xl font-bold'>Boosts</p>
					<p className='text-ColorsChapter2-500 text-sm text-center'>Upgrades for Jockey bots</p>
					<div className='mt-5 h-full h-pc:px-1'>
						<BoostTable />
					</div>
				</div>
			</div>
		</>
	)
}

export default Boostpage