import { memo, useCallback, useRef, useState } from 'react'
import { useTelegram } from "../../../hooks/useTelegram"
import '../../ui/gamepage/gamemodule.css'

interface TapText {
    text: string
    x: number
    y: number
    id: number
}

interface Props {
    onTap: () => void
    coin: number
    water: number
}

interface TelegramWebApp {
    HapticFeedback: {
        impactOccurred: (type: 'light' | 'medium' | 'heavy') => void
        notificationOccurred: (type: 'error' | 'success' | 'warning') => void
        selectionChanged: () => void
    }
}

interface WindowWithTelegram extends Window {
    Telegram?: {
        WebApp?: TelegramWebApp
    }
}


const TapTextComponent: React.FC<Props> = memo(({ onTap, coin, water }) => {
    const [tapTexts, setTapTexts] = useState<TapText[]>([])
    const nextId = useRef(0)
    const lastTouchesRef = useRef<{ identifier: number }[]>([])
    const { Telegram } = window as WindowWithTelegram
    const tg = useTelegram()

    const addTapText = useCallback((text: string, x: number, y: number) => {
        const id = nextId.current
        nextId.current += 1

        setTapTexts(prev => [
            ...prev,
            { text, x, y, id }
        ])

        setTimeout(() => {
            setTapTexts(prev => prev.filter(tapText => tapText.id !== id))
        }, 1000)
    }, [])

    const triggerHapticFeedback = useCallback(() => {
        if (navigator?.vibrate) {
            navigator.vibrate?.(50)
        }
    }, [Telegram, navigator.vibrate])

    const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        tg.tg.HapticFeedback.impactOccurred('light')
        if (water > 0) {
            const uniqueTouches = Array.from(event.touches).filter(touch => {
                return !lastTouchesRef.current.some(t => t.identifier === touch.identifier)
            })

            const touchCount = uniqueTouches.length

            uniqueTouches.forEach(touch => {
                const { clientX: x, clientY: y, identifier } = touch
                lastTouchesRef.current.push({ identifier })

                addTapText('x1', x, y)

                triggerHapticFeedback()
            })

            for (let i = 0; i < touchCount; i++) {
                onTap()
            }

            Array.from(event.changedTouches).forEach(touch => {
                lastTouchesRef.current = lastTouchesRef.current.filter(t => t.identifier !== touch.identifier)
            })
        }
    }, [water, addTapText, onTap, triggerHapticFeedback])

    return (
        <div
            onTouchStart={handleTouchStart}
            onTouchMove={(e) => e.preventDefault()}
            style={{
                width: '100vw',
                height: '82vh',
                position: 'absolute',
                top: 0,
                left: 0,
                pointerEvents: 'auto',
                zIndex: 10,
                touchAction: 'none',
            }}
        >
            {tapTexts.map(({ text, x, y, id }) => (
                <div
                    key={id}
                    className="tapText font-bold text-white text-lg"
                    style={{
                        top: y - 20,
                        left: x,
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        transition: 'opacity 1s ease-out, transform 1s ease-out',
                    }}
                >
                    <div className="fadeOut font-Montserrat">
                        {text}
                    </div>
                </div>
            ))}
        </div>
    )
})

export default TapTextComponent
