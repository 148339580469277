import { useNecessary } from '../../../hooks/necessary'
import useApi from '../../../services/api/apiHandlerService'
import Coin from '../../images/coin.png'
import useStore from '../../store/zustand'
type Props = {
	reward: number
	getFriends: () => void
}

const InviteLink:React.FC<Props> = ({reward,getFriends}) => {
	const {identityId} = useStore()
	const {getData} = useNecessary()
	const api = useApi()

	const claimFriendReward = async () => {
		const res = await api({
			url: `/friend/claim`,
			method: 'POST',
			headers: {
				'x-user-id': identityId
			}
		})
		getData()
		getFriends()
	}

	return (
		<>
		<div>
      
		  <div className='p-3 px-4 rounded-xl border-2 border-ColorsChapter2-400' style={{background: 'linear-gradient(82.14deg, rgba(141, 102, 51, 0.56) 0%, rgba(12, 12, 13, 0.16) 98.94%)'
			}}>
				<p className='text-center text-ColorsChapter2-500'>You have already earned</p>
				<div className='flex justify-center gap-1 items-center mt-2'>
					<img src={Coin} alt="Coin" className='w-[40px] h-[40px]' />
					<p className='text-white font-bold text-lg'>{reward} Points</p>
				</div>

				{reward > 0 ? (
					<button className='bg-ColorsChapter2-50 w-full text-center p-3 mt-3 rounded-lg' onClick={() => claimFriendReward()}>
					Claim
				  </button>
				) : (
					<button className='bg-ColorsChapter2-150 text-white opacity-55 w-full text-center p-3 mt-3 rounded-lg'>
					Claim
				  </button>
				)}
				
		  </div>

		</div>
		</>
	)
}

export default InviteLink