import Coin from '../images/coin.png'
import avatarka from '../images/image.png'
import { ReactComponent as Media } from '../images/media.svg'
import useStore from '../store/zustand'
const Header = () => {
	const { currentCoin } = useStore()
	const { avatar } = useStore()
	const imageSrc = `data:image/png;base64,${avatar}`


	return (
		<>
			<header className='flex justify-center'>
				<div
					className={`h-[80px] flex items-center mt-5 rounded-3xl border border-ColorsChapter2-350  max-w-[350px] w-full`} style={{
						background: 'linear-gradient(180deg, #171719 0%, rgba(23, 23, 25, 0.4) 100%)'
					}}>
					<div className='px-3 w-full'>
						<div className='flex justify-between items-center'>
							<div className='flex gap-3'>
								<div className='border border-ColorsChapter2-300 rounded-full p-[4px] h-[60px] w-[60px] flex items-center justify-center'>
									<img src={avatar ? imageSrc : avatarka} alt="" className='rounded-full h-full w-full' />
								</div>

								<div className='flex flex-col text-[11px] text-white'>
									<p className='text-ColorsChapter2-100'>Balance</p>
									<div style={{ background: 'linear-gradient(82.14deg, rgba(248, 193, 122, 0.32) 0%, rgba(60, 46, 29, 0.08) 98.94%)', }} className='min-w-[100px] w-full h-[35px] mt-1 flex items-center gap-1 rounded-3xl border border-ColorsChapter2-400 p-2 pr-3'>
										<div>
											<img src={Coin} className='h-[25px] w-[25px]' />
										</div>
										<div className='text-ColorsChapter2-50 text-base font-medium'>
											<p>{currentCoin}</p>
										</div>
									</div>
								</div>
							</div>


							<div className='h-[55px] px-[5px] flex items-center justify-center bg-ColorsChapter2-150 border border-ColorsChapter2-200 rounded-3xl'>
								<Media className='w-[18px] h-[18px]' />
							</div>

						</div>
					</div>
				</div>
			</header>

		</>
	)
}

export default Header