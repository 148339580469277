import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { ReactComponent as Close } from '../components/images/close.svg'
import { ReactComponent as SnackbarIcon } from '../components/images/snackbarIcon.svg'


interface props {
	message: string
  setCopySuccess: React.Dispatch<React.SetStateAction<string>>
}

const SuccessSnackbar = ({ message, setCopySuccess}:props) => {
    
    return (
      <Snackbar
      open={message !== ''}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          borderRadius: '15px',
          position: 'absolute',
          top: '80vh',
          zIndex: 99
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Alert
        icon={false}
        sx={{
          background: 'linear-gradient(180deg, #040404 0%, rgba(23, 23, 25) 100%)',
          border: 'solid 1px #28282A',
          color: 'white',
          '& .MuiAlert-icon': {
            color: 'white',
          },
        }}
      >
        <div className='flex items-center gap-2'>
          <div className='w-[300px] bg-myColors-850 h-[20px] px-1 flex items-center rounded-2xl text-[12px] gap-2 font-medium'>
            <SnackbarIcon className='w-[20px]'/>
            <p className='mt-[2px] text-base' style={{color: `#6C717D`}}>
             {message}
            </p>
          </div>

          <button className='ml-4' onClick={() => setCopySuccess('')}>
            <Close className='w-[30px] h-[20px]' />
          </button>

        </div>
      </Alert>
    </Snackbar>
    );
};

export default SuccessSnackbar;
