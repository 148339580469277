import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/base/header'
import useStore from '../components/store/zustand'
import '../components/ui/background.css'
import SelectCamel from '../components/ui/homepage/selectCamel'
import { useNecessary } from '../hooks/necessary'
import { useTelegram } from '../hooks/useTelegram'

const Homepage = () => {
	const {avatar} = useStore()
	const imageSrc = `data:image/png;base64,${avatar}`
	const {getData} = useNecessary()
	const navigate = useNavigate()
	const {tg} = useTelegram()

	useEffect(() => {
			getData()
	}, [])

	useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    return () => {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
    };
  }, []);
	
	return (
		<>
		<Header/>
		<div className='w-full flex justify-center items-center text-white'>
			<div className={`w-full max-w-[350px] justify-center items-center`}>
					<div className=''>
		        <SelectCamel/>
					 </div>
			</div>
		</div>
		</>
	)
}

export default Homepage
