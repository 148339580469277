import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Router from './router/router'
import { ErrorProvider, useError } from './services/errors/errorContext'
import ErrorSnackbar from './services/errors/errorSnackbar'

interface TelegramWebApp {
    close: () => void;
    platform?: string;
    BackButton?: {
        show: () => void;
        hide: () => void;
        onClick: (callback: () => void) => void;
        offClick: (callback: () => void) => void;
    };
}

const tg: TelegramWebApp | undefined = (window as any).Telegram?.WebApp;


function App() {
  const navigate = useNavigate()
  const location = useLocation()

    useEffect(() => {
        if ((window as any).Telegram && (window as any).Telegram.WebApp && (window as any).Telegram.WebApp.expand) {
            (window as any).Telegram.WebApp.expand();
        }
    }, []);

  useEffect(() => {
  const handleBackButton = () => {
      navigate(-1)
    };
  if (location.pathname === '/shop') {
    if (tg?.BackButton) {
        tg.BackButton.show(); 
        tg.BackButton.onClick(handleBackButton);

        return () => {
            tg.BackButton?.offClick(handleBackButton);
            tg.BackButton?.hide();
        };
    }
  }
  }, [location.pathname]);

  return (
    <ErrorProvider>
      <AppContent />
    </ErrorProvider>
  );
}

function AppContent() {
  const { error, setError } = useError();

  return (
    <>
      <Router />
      <ErrorSnackbar error={error} onClose={() => setError('')} />
    </>
  );
}

export default App;
